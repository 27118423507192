/* eslint-disable jsx-a11y/label-has-associated-control */
/* Disabled associated control rule because it doesn't realize TextField is an input */
import { Divider, FormControl, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TextField } from '../TextField';
import { useStyles } from './styles';

export interface AliasFieldProps {
  id: string;
  inputName(key: string): string;
  required: boolean;
  index: number;
  shouldValidate?: boolean;
  fieldHasContent: (hasContent: boolean) => void;
}

interface AliasFieldValues {
  // ignoring camelcase since these correspond to the name the back end is expecting
  // eslint-disable-next-line camelcase
  first_name: string;
  // eslint-disable-next-line camelcase
  middle_name: string;
  // eslint-disable-next-line camelcase
  last_name: string;
}

const hasInput = (values: AliasFieldValues): boolean =>
  Object.values(values).some(val => {
    return (val as string).trim().length > 0;
  });

export const AliasField: React.VFC<AliasFieldProps> = ({
  inputName,
  required,
  id,
  index,
  shouldValidate,
  fieldHasContent,
}) => {
  const emptyAliasFields: AliasFieldValues = {
    first_name: '',
    middle_name: '',
    last_name: '',
  };

  const styles = useStyles();
  // const intl = useIntl();
  const [aliasFields, setAliasFields] = useState(emptyAliasFields);

  const stripPrefix = useCallback(
    (inputId: string) => {
      return inputId.replace(`${id}-`, '').replace(`-${index}`, '');
    },
    [id, index],
  );

  const formIsEmpty = useCallback(
    (key: string, value: string) => {
      const firstNameBlank = aliasFields.first_name.length === 0;
      const middleNameBlank = aliasFields.middle_name.length === 0;
      const lastNameBlank = aliasFields.last_name.length === 0;
      if (value === '') {
        if (
          key === 'first_name' &&
          !firstNameBlank &&
          middleNameBlank &&
          lastNameBlank
        ) {
          return true;
        }
        if (
          key === 'middle_name' &&
          firstNameBlank &&
          !middleNameBlank &&
          lastNameBlank
        ) {
          return true;
        }
        if (
          key === 'last_name' &&
          firstNameBlank &&
          middleNameBlank &&
          !lastNameBlank
        ) {
          return true;
        }
      }
      return false;
    },
    [
      aliasFields.first_name.length,
      aliasFields.last_name.length,
      aliasFields.middle_name.length,
    ],
  );

  const handleInputs = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, id: inputId } = event.target;
      const key = inputId === id ? 'first_name' : stripPrefix(inputId);
      setAliasFields(oldAlias => ({
        ...oldAlias,
        [key]: value,
      }));
      fieldHasContent(formIsEmpty(key, value));
    },
    [id, stripPrefix, fieldHasContent, formIsEmpty],
  );

  const nestedName = useCallback(
    (field?: keyof AliasFieldValues): string =>
      field
        ? `${inputName(id)}[${index}][][${field}]`
        : `${inputName(id)}[${index}][]`,
    [id, inputName, index],
  );

  const forceRequire = required || hasInput(aliasFields);
  const showError = (value: string) =>
    forceRequire && shouldValidate && value.length === 0;

  return (
    <div onChange={handleInputs} data-aliasroot id={`alias_field_${index}`}>
      <FormControl component="fieldset" className={styles.aliasRoot}>
        <FormControl component="fieldset">
          <label>
            <Typography>
              <FormattedMessage
                defaultMessage="First Name"
                description="First Name text field title"
              />
            </Typography>
            <TextField
              id={`${id}`}
              name={nestedName('first_name')}
              required={forceRequire}
              error={showError(aliasFields.first_name)}
              value={aliasFields.first_name}
              valueMissingText="First Name is required"
            />
          </label>
          <label>
            <Typography>
              <FormattedMessage
                defaultMessage="Middle Name"
                description="Middle Name text field title"
              />
            </Typography>
            <TextField
              id={`${id}-middle_name-${index}`}
              name={nestedName('middle_name')}
              value={aliasFields.middle_name}
              valueMissingText="Middle Name is required"
            />
          </label>
          <label>
            <Typography>
              <FormattedMessage
                defaultMessage="Last Name"
                description="Last Name text field title"
              />
            </Typography>
            <TextField
              id={`${id}-last_name-${index}`}
              name={nestedName('last_name')}
              required={forceRequire}
              error={showError(aliasFields.last_name)}
              value={aliasFields.last_name}
              valueMissingText="Last Name is required"
            />
          </label>
          <Divider />
        </FormControl>
      </FormControl>
    </div>
  );
};
