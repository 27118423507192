/* eslint-disable jsx-a11y/label-has-associated-control */
/* Disabled associated control rule because it doesn't realize TextField is an input */
// import { Button, Divider, FormControl, Typography } from '@material-ui/core';
import { Box, Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';

import { AliasField } from '../AliasField/AliasField';

export interface AliasesFieldProps {
  inputName(key: string): string;
  required: boolean;
  id: string;
  shouldValidate?: boolean;
}

export const AliasesField: React.VFC<AliasesFieldProps> = ({
  inputName,
  required,
  id,
  shouldValidate,
}) => {
  // Multiple AliasFields
  const [aliasesFields, setAliasesField] = useState([
    { value: '', key: Math.random(), hasContent: false },
  ]);

  const handleAddField = () => {
    setAliasesField([
      ...aliasesFields,
      { value: '', key: Math.random(), hasContent: false },
    ]);
  };

  const handleRemoveField = () => {
    setAliasesField(aliasesFields.slice(0, -1));
  };

  const handleDataFromAddressField = (formIsEmpty: boolean, index: number) => {
    aliasesFields[index].hasContent = !formIsEmpty;
    setAliasesField([...aliasesFields]);
  };

  const doesLastAliasFieldHasContent = () => {
    return aliasesFields.slice(-1)[0].hasContent;
  };

  return (
    <>
      {aliasesFields.map((aliasesField, index) => (
        <Box mb={4} key={aliasesField.key}>
          {`Alias ${index + 1}`}
          <AliasField
            inputName={inputName}
            id={`${id}`}
            required={required}
            shouldValidate={shouldValidate}
            index={index + 1}
            fieldHasContent={(hasContent: boolean) => {
              handleDataFromAddressField(hasContent, index);
            }}
          />
        </Box>
      ))}
      <Grid container spacing={2}>
        {doesLastAliasFieldHasContent() && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddField}
            >
              Add Another Alias
            </Button>
          </Grid>
        )}
        {aliasesFields.length > 1 && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRemoveField}
            >
              Remove Last Alias
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};
