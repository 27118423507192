import React from 'react';

import { TextField } from '../TextField';

type MuiProps = React.ComponentProps<typeof TextField>;

export type TextAreaProps = Omit<MuiProps, 'multiline'>;

export const TextArea: React.VFC<TextAreaProps> = ({ ...rest }) => {
  return <TextField {...rest} multiline rows={3} rowsMax={25} />;
};
