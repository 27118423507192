/* N.B. This file MUST remain JavaScript! Our Webpack config `require`s it! */

/**
 * List of Fountain-known functions within `applicant-ui` which are responsible
 * for injecting customer-provided third-party JavaScript
 *
 * Leveraged for Terser configuration to avoid minifying the function
 * names out of production bundles. This is so they can be checked for in stack
 * traces of errors to determine how they should be logged to Datadog.
 *
 */
export const THIRD_PARTY_SCRIPT_INJECTORS = ['useCustomJs', 'TrackerJs'];
