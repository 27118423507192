import { createStyles, makeStyles } from '@material-ui/core/styles';
import { IStyleCustomization } from 'react-dropzone-uploader';

export const useStyles = makeStyles(theme =>
  createStyles({
    uploadWrapper: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 40,

      [theme.breakpoints.up('sm')]: {
        minHeight: 250,
      },
    },
    uploadInputContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 140,

      '& span[role="button"]': {
        margin: `${theme.spacing(4)}px auto ${theme.spacing(2)}px`,
      },

      [theme.breakpoints.up('sm')]: {
        height: 250,
      },
      [theme.breakpoints.down('xs')]: {
        '& p:first-of-type': {
          display: 'none',
        },
      },
    },
    prevFilesWrapper: {
      marginBottom: '1em',
    },
    closeIcon: {
      verticalAlign: 'middle',
      fontSize: '16px',
      marginLeft: '10px',
      marginBottom: '2px',
      color: 'red',
    },
    active: {
      backgroundColor: theme.palette.common.gray300,
    },
    loadingContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 240,
      border: `1px dashed ${theme.palette.common.gray300}`,
      '& p': {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up('sm')]: {
        height: 450,
      },
    },
    circularProgress: {
      position: 'relative',
    },
    previewWrapper: {
      textAlign: 'left',
    },
    imagePreview: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 240,
      width: '100%',
      backgroundColor: theme.palette.common.gray100,

      '& img': {
        maxHeight: '100%',
        maxWidth: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        height: 450,
      },
    },
    previewButton: {
      padding: theme.spacing(2),
      textAlign: 'left',
    },
    deleteButton: {
      margin: `${theme.spacing(2)}px auto ${theme.spacing(2)}px 0`,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    iconDark: {
      filter: 'brightness(0)',
    },
    error: {
      color: theme.palette.common.red400,
    },
  }),
);

export const dropzoneStyles = {
  dropzone: {
    border: '1px dashed #c4c4c4',
    width: '100%',
    textAlign: 'center',
    marginBottom: '2em',
    maxHeight: '420px',
  },
  inputLabel: {
    position: 'relative',
    flex: 1,
    display: 'flex',
  },
  inputLabelWithFiles: {
    marginBottom: '16px',
  },
} as IStyleCustomization<React.CSSProperties>;
