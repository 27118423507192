import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from 'react-router-dom';

import { FileUploadRecollection } from 'features/FileUploadRecollection/FileUploadRecollection';

const StageManager = React.lazy(() => import('./components/StageManager'));
const ContextsProvider = React.lazy(
  () => import('./features/ContextsProvider'),
);
const ApplicationForm = React.lazy(
  () => import('features/ApplicationForm/ApplicationForm'),
);

const BrandPreview = React.lazy(() => import('features/BrandPreview'));

const RemoteI9AuthorizationForm = React.lazy(
  () => import('features/I9Stage/RemoteI9AuthorizationForm'),
);

const emptyFallback = <div />;

const FileUploadRecollectionRoutes = (): JSX.Element => {
  interface ApplicationParams {
    accountSlug: string;
    applicantExternalId: string;
  }

  const { accountSlug, applicantExternalId } = useParams<ApplicationParams>();
  return (
    <Suspense fallback={emptyFallback}>
      <ContextsProvider>
        <FileUploadRecollection
          accountSlug={accountSlug}
          applicantExternalId={applicantExternalId}
        />
      </ContextsProvider>
    </Suspense>
  );
};

const StageRoutes = (): JSX.Element => {
  return (
    <Suspense fallback={emptyFallback}>
      <ContextsProvider>
        <StageManager />
      </ContextsProvider>
    </Suspense>
  );
};

const RemoteI9Routes = (): JSX.Element => {
  return (
    <Suspense fallback={emptyFallback}>
      <RemoteI9AuthorizationForm />
    </Suspense>
  );
};

const ApplicationFormRoutes = (): JSX.Element => {
  interface FormRouteParams {
    accountSlug: string;
    funnelId: string;
  }

  const { accountSlug, funnelId } = useParams<FormRouteParams>();

  return (
    <Suspense fallback={emptyFallback}>
      <ApplicationForm accountSlug={accountSlug} funnelId={funnelId} />
    </Suspense>
  );
};

const BrandPreviewRoute = (): JSX.Element => {
  return (
    <Suspense fallback={emptyFallback}>
      <BrandPreview />
    </Suspense>
  );
};

export const Root = (): JSX.Element => (
  <Router>
    <Switch>
      {/* N.B. Hanging `BrandPreview` behind `/apply` is hacky and we'd do
          well to ensure `brand_preview` cannot end up becoming an
          `accountSlug`. Or possibly by adding a new route to
          `cf-s3-frontend-worker` itself that isn't conflated at all */}
      <Route path="/apply/brand_preview" component={BrandPreviewRoute} />
      <Route
        path="/apply/:sha([0-9a-f]{7})/brand_preview"
        component={BrandPreviewRoute}
      />
      <Route
        path="/apply/:accountSlug/opening/:funnelId"
        component={ApplicationFormRoutes}
      />
      <Route
        path="/apply/:sha([0-9a-f]{7})/:accountSlug/opening/:funnelId"
        component={ApplicationFormRoutes}
      />
      <Route
        path="/apply/:accountSlug/remoteI9Authorization/:i9FormExternalId"
        component={RemoteI9Routes}
      />
      <Route
        path="/apply/:accountSlug/applications/:applicantExternalId/file_upload_requests"
        component={FileUploadRecollectionRoutes}
      />
      <Route
        path="/apply/:accountSlug/applications/:applicantExternalId/"
        component={StageRoutes}
      />
      <Route
        path="/apply/:sha([0-9a-f]{7})/:accountSlug/applications/:applicantExternalId"
        component={StageRoutes}
      />
    </Switch>
  </Router>
);
