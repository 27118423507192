import { datadogLogs } from '@datadog/browser-logs';
import { Button, FormControl, Typography } from '@material-ui/core';
import { FileUpload as FileUploadFields } from 'api-clients/monolith';
import { RouteParams } from 'contexts/appContext';
import { Close } from 'icons/fds';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState, VFC } from 'react';
import Dropzone, { formatBytes, IFileWithMeta } from 'react-dropzone-uploader';
import { classNames } from 'react-extras';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { domainMeta } from 'utils/domainMeta';

import UploadIcon from '../../images/upload-file.svg';
import { dropzoneStyles, useStyles } from './styles';
import {
  FileDetails,
  getSecureDataUrl,
  getUploadParams,
  runBlurryDetection,
  setDoneInput,
} from './utils';

interface ErrorDetails {
  message: JSX.Element;
  type: string;
}

interface ErrorMessagesMap {
  [key: string]: ErrorDetails;
}

const ERROR_MESSAGES: ErrorMessagesMap = {
  error_required: {
    message: (
      <FormattedMessage
        defaultMessage="Please upload a file."
        description="Required upload error message."
      />
    ),
    type: 'required',
  },
  error_file_size: {
    message: (
      <FormattedMessage
        defaultMessage="The file is too large. Please try again."
        description="File size exceeded error message."
      />
    ),
    type: 'size',
  },
  error_upload: {
    message: (
      <FormattedMessage
        defaultMessage="The upload failed. Please try again."
        description="Failed upload error message."
      />
    ),
    type: 'upload',
  },
  exception_upload: {
    message: (
      <FormattedMessage
        defaultMessage="The upload failed. Please try again."
        description="Failed upload error message."
      />
    ),
    type: 'upload',
  },
  error_file_type: {
    message: (
      <FormattedMessage
        defaultMessage="Please upload a valid image or document file type, such as .jpg, .png, .pdf, or .docx"
        description="File type upload error message."
      />
    ),
    type: 'type',
  },
};

interface InputContentProps {
  maxSizeBytes: number;
  active: boolean;
}

const InputContent: VFC<InputContentProps> = ({ maxSizeBytes, active }) => {
  const styles = useStyles();
  const intl = useIntl();

  return (
    <FormControl
      className={classNames(styles.uploadInputContainer, {
        [styles.active]: active,
      })}
    >
      <Button
        component="span"
        variant="outlined"
        startIcon={
          <img
            alt={intl.formatMessage({
              defaultMessage: 'Upload',
              description: 'Upload Icon',
            })}
            className={styles.iconDark}
            src={UploadIcon}
          />
        }
      >
        <FormattedMessage
          defaultMessage="Upload File"
          description="File upload input button"
        />
      </Button>
      <Typography>
        <FormattedMessage
          defaultMessage="or drag and drop here"
          description="Indicates drag and drop option is available"
        />
      </Typography>
      <Typography>
        <FormattedMessage
          defaultMessage="Max file size:"
          description="Indicates file size restriction"
        />{' '}
        {formatBytes(maxSizeBytes)}
      </Typography>
    </FormControl>
  );
};

export interface MultiFileUploadProps {
  id: string;
  required: boolean;
  name: string;
  uploadFields: FileUploadFields;
  shouldValidate?: boolean;
  dataFieldId: number;
  selectedFiles?: string;
}

export const MultiFileUpload: VFC<MultiFileUploadProps> = ({
  id,
  required,
  name,
  uploadFields,
  shouldValidate,
  dataFieldId,
  selectedFiles,
}) => {
  import('react-dropzone-uploader/dist/styles.css'); // It is necessary to import it here to prevent the CSS from affecting all the dropzones.
  const styles = useStyles();
  const dropzoneWrapper = useRef<HTMLDivElement>(null);
  const [files, setFiles] = useState<IFileWithMeta[]>([]);
  const [displayLoading, setDisplayLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ErrorDetails>();
  const [isBlurry, setIsBlurry] = useState(false);
  const [initialFiles, setInitialFiles] = useState<FileDetails[]>([]);
  const { accountSlug, applicantExternalId } = useParams<RouteParams>();

  const getFileInput = (): HTMLInputElement | null => {
    if (!dropzoneWrapper.current) {
      return null;
    }
    return dropzoneWrapper.current.querySelector('input[type="file"]');
  };
  const valInput = () =>
    dropzoneWrapper.current?.querySelector(
      `input[name="${name}"]`,
    ) as HTMLInputElement;

  useEffect(() => {
    const prevFiles = selectedFiles ? (JSON.parse(selectedFiles) as []) : [];
    if (prevFiles.length > 0) {
      setInitialFiles(prevFiles);
      valInput().setAttribute('value', JSON.stringify(prevFiles));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  const addFile = (newFile: IFileWithMeta) => {
    setFiles([...files, newFile]);
    setDoneInput(
      [...files, newFile],
      valInput(),
      uploadFields,
      dataFieldId,
      initialFiles,
    );
  };

  const removeFile = (deletedFile: IFileWithMeta) => {
    const updatedFiles = files.filter(
      file => deletedFile.meta.id !== file.meta.id,
    );
    setFiles(updatedFiles);
    setDoneInput(
      updatedFiles,
      valInput(),
      uploadFields,
      dataFieldId,
      initialFiles,
    );
  };

  useEffect(() => {
    const input = getFileInput();
    if (required) {
      input?.setAttribute('required', '');
    } else {
      input?.removeAttribute('required');
    }
  }, [required]);

  useEffect(() => {
    if (required && shouldValidate && isEmpty(files) && isEmpty(initialFiles)) {
      setErrorMessage(ERROR_MESSAGES.error_required);
    } else if (errorMessage?.type === 'required') {
      setErrorMessage(undefined);
    }
  }, [shouldValidate, required, errorMessage, initialFiles, files]);

  const checkErrorDisplay = (): boolean => {
    const fileTypeError = errorMessage?.type === 'type';
    const uploadFailed = errorMessage?.type === 'upload';
    const notSizeError = errorMessage?.type !== 'size';
    const notInErrorState = !errorMessage || displayLoading || !shouldValidate;

    if (fileTypeError || uploadFailed) {
      return true;
    }
    if (notSizeError && notInErrorState) {
      return false;
    }
    const requiredError = errorMessage.type === 'required';
    return !requiredError ? true : required;
  };

  const handleChange: (
    file: IFileWithMeta | undefined,
    status: string,
  ) => void = (file, status) => {
    let loading = false;

    switch (status) {
      case 'removed':
        if (files && file) {
          removeFile(file);
        }
        break;
      case 'preparing':
        loading = true;
        break;
      case 'getting_upload_params':
      case 'uploading':
      case 'headers_received':
        loading = true;
        break;
      case 'done':
        if (file) {
          const invalidFileTypes = ['html', 'x-msdownload'];
          const isInvalidType = invalidFileTypes.some(
            type => file.meta.type.includes(type) || file.meta.type === '',
          );

          if (isInvalidType) {
            file?.remove();
            setErrorMessage(ERROR_MESSAGES.error_file_type);
            break;
          }
          addFile(file);

          if (file.meta.type.includes('image')) {
            if (uploadFields.lorgnette_endpoint) {
              const lorgnetteEndpoint =
                uploadFields.lorgnette_endpoint.includes('http')
                  ? uploadFields.lorgnette_endpoint
                  : `${domainMeta.monolithOrigin}${uploadFields.lorgnette_endpoint}`;
              const secureDataUrl = getSecureDataUrl(
                file,
                uploadFields,
                accountSlug,
                applicantExternalId,
                dataFieldId,
              );

              runBlurryDetection(secureDataUrl, lorgnetteEndpoint)
                .then(blurryDetected => {
                  setIsBlurry(blurryDetected);
                })
                .catch((err: unknown) => {
                  datadogLogs.logger.error(
                    err instanceof Error
                      ? err.message
                      : 'Failed to return blurry state.',
                  );
                });
            } else {
              datadogLogs.logger.error(
                'Failed to run blurry state check. LORGNETTE_ENDPOINT environment variable not set',
              );
            }
          }

          setErrorMessage(undefined);
        }
        break;
      default:
        break;
    }

    if (
      status.split('_')[0] === 'error' ||
      status.split('_')[0] === 'exception'
    ) {
      file?.remove();
      setErrorMessage(ERROR_MESSAGES[status]);
    }

    setDisplayLoading(loading);
  };

  const removePreviousFile = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    file: FileDetails,
  ) => {
    event.preventDefault();
    const updatedInitialFiles = initialFiles.filter(
      initialFile => file.blob_id !== initialFile.blob_id,
    );
    setInitialFiles(updatedInitialFiles);
    setDoneInput(
      files,
      valInput(),
      uploadFields,
      dataFieldId,
      updatedInitialFiles,
    );
  };

  const renderUploadedFile = (file: FileDetails) => {
    return (
      <div>
        {file.filename}
        <Close
          className={styles.closeIcon}
          onClick={event => removePreviousFile(event, file)}
        />
      </div>
    );
  };

  const renderPreviouslyUploadedFiles = () => {
    return (
      !isEmpty(initialFiles) && (
        <div id="prevFiles" className={styles.prevFilesWrapper}>
          {initialFiles.map(renderUploadedFile)}
        </div>
      )
    );
  };

  return (
    <>
      <div ref={dropzoneWrapper} className={styles.uploadWrapper}>
        <Dropzone
          styles={dropzoneStyles}
          onChangeStatus={handleChange}
          maxSizeBytes={uploadFields?.max_size}
          getUploadParams={getUploadParams(uploadFields, dataFieldId)}
          inputContent={(_inputProps, dropzoneProps) => (
            <InputContent key="inputContent" {...dropzoneProps} />
          )}
        />
        {checkErrorDisplay() && (
          <Typography className={styles.error}>
            {errorMessage?.message}
          </Typography>
        )}
        {isBlurry && !displayLoading && (
          <Typography className={styles.error}>
            <FormattedMessage
              defaultMessage="This image appears blurry. Retake the photo with a steady hand in a
              well lit space for best results."
              description="Blurry image detected warning"
            />
          </Typography>
        )}
        <input id={id} type="hidden" name={name} />
      </div>
      {renderPreviouslyUploadedFiles()}
    </>
  );
};
