import React from 'react';

const calculateAndSetAspectRatio = (node: HTMLIFrameElement) => {
  if (node.width && node.height) {
    const calculatedRatio = Number(node.width) / Number(node.height);
    node.setAttribute('style', `aspect-ratio: ${calculatedRatio.toFixed(5)}`);
    node.removeAttribute('width');
    node.removeAttribute('height');
  } else {
    // default
    node.setAttribute('style', 'aspect-ratio: 16 / 9');
  }
};

export const useResizeEmbeds = ({ text }: { text: string }) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (ref.current) {
      ref.current
        .querySelectorAll('iframe')
        .forEach(calculateAndSetAspectRatio);
    }
  }, [text]);

  return { ref };
};
