type Direction = 'rtl' | 'ltr';
const rtlLanguages = ['he', 'ar'];

const isDirectionType = (keyInput: string): keyInput is Direction => {
  return ['rtl', 'ltr'].includes(keyInput);
};

export const setBodyDirection = (direction: Direction) => {
  document.body.dir = direction;
};

export const getDirection = (): Direction => {
  // Example loading from a query Parameter
  const urlParams = new URLSearchParams(window.location.search);
  const directionParam = urlParams.get('direction') ?? '';
  if (isDirectionType(directionParam)) {
    return directionParam;
  }

  // Example using the client language
  if (rtlLanguages.includes(navigator.language)) {
    return 'rtl';
  }

  // Default
  return 'ltr';
};
