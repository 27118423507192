import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    richText: {
      '& li': {
        lineHeight: '1.75rem',
      },
      '& iframe': {
        width: '100%',
        height: '100%',
      },
      '& img': {
        maxHeight: '100%',
        maxWidth: '100%',
      },
      '& a': {
        color: theme.palette.primary.main,
        '&:hover': {
          filter: 'brightness(80%)',
        },
        '&:active': {
          filter: 'brightness(90%)',
        },
      },
    },
  }),
);
