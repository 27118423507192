import { Typography as MuiTypography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import React, { forwardRef } from 'react';

import type { ColorToken } from '@fountain/fds-tokens/types';

/**
 * AXDS color tokens
 *
 * N.B. Though we are currently proxying FDS color tokens as if they are AXDS
 * color tokens, there is an upcoming spec change which shifts some values and
 * introduces new tokens
 */
export type AXDSColor = ColorToken;

const useColorStyles = makeStyles((theme: Theme) =>
  createStyles<AXDSColor, Record<never, never>>({
    black: { color: theme.palette.common.black },
    blue100: { color: theme.palette.common.blue100 },
    blue200: { color: theme.palette.common.blue200 },
    blue400: { color: theme.palette.common.blue400 },
    blue500: { color: theme.palette.common.blue500 },
    blue50: { color: theme.palette.common.blue50 },
    blue600: { color: theme.palette.common.blue600 },
    gray100: { color: theme.palette.common.gray100 },
    gray200: { color: theme.palette.common.gray200 },
    gray300: { color: theme.palette.common.gray300 },
    gray400: { color: theme.palette.common.gray400 },
    gray500: { color: theme.palette.common.gray500 },
    gray600: { color: theme.palette.common.gray600 },
    gray700: { color: theme.palette.common.gray700 },
    gray800: { color: theme.palette.common.gray800 },
    green100: { color: theme.palette.common.green100 },
    green400: { color: theme.palette.common.green400 },
    green600: { color: theme.palette.common.green600 },
    green800: { color: theme.palette.common.green800 },
    orange100: { color: theme.palette.common.orange100 },
    orange200: { color: theme.palette.common.orange200 },
    orange400: { color: theme.palette.common.orange400 },
    orange600: { color: theme.palette.common.orange600 },
    orange800: { color: theme.palette.common.orange800 },
    red100: { color: theme.palette.common.red100 },
    red400: { color: theme.palette.common.red400 },
    red600: { color: theme.palette.common.red600 },
    red800: { color: theme.palette.common.red800 },
    white: { color: theme.palette.common.white },
  }),
);

/**
 * AXDS typography tokens
 *
 * @see {@link https://www.figma.com/file/TR4yYLzvYHPznF5zBgyHOA/Applicant-Experience-Design-System?node-id=889%3A16961}
 */
export type AXDSTypographyVariant =
  | 'body'
  | 'bodyCompact'
  | 'subtitle'
  | 'subtitleCompact'
  | 'headingXL'
  | 'headingLG'
  | 'headingMD'
  | 'headingSM'
  | 'headingXS';

const useVariantStyles = makeStyles((theme: Theme) =>
  createStyles<AXDSTypographyVariant, Record<never, never>>({
    body: theme.typography.body,
    bodyCompact: theme.typography.bodyCompact,
    subtitle: theme.typography.subtitle,
    subtitleCompact: theme.typography.subtitleCompact,
    headingXL: theme.typography.headingXL,
    headingLG: theme.typography.headingLG,
    headingMD: theme.typography.headingMD,
    headingSM: theme.typography.headingSM,
    headingXS: theme.typography.headingXS,
  }),
);

type MuiProps = React.ComponentProps<typeof MuiTypography>;

export type TypographyProps = Omit<MuiProps, 'color' | 'variant'> & {
  color?: AXDSColor;
  component?: React.ElementType;
  variant?: AXDSTypographyVariant;
};

/**
 * An AXDS Facade over MUI's `Typography` which is type constrained to expose
 * only `AXDSTypographyVariant` tokens via the `variant` prop
 */
export const Typography: React.VFC<TypographyProps> = forwardRef(
  (
    { className, color = 'gray800', variant = 'body', ...props },
    forwardedRef,
  ) => {
    const colorStyles = useColorStyles();
    const variantStyles = useVariantStyles();

    return (
      <MuiTypography
        className={cx(variantStyles[variant], colorStyles[color], className)}
        // N.B. `color` itself must not be prop drilled down to MUI itself or
        // it will surface PropType warnings!
        color="inherit"
        ref={forwardedRef}
        // N.B. `variant` itself must not be prop drilled down to MUI itself or
        // it will surface PropType warnings!
        variant="inherit"
        {...props}
      />
    );
  },
);
