import cx from 'classnames';
import React from 'react';

import { useStyles } from './styles';

export interface RichTextProps {
  text: string;
  className?: string;
}

export const RichText = React.forwardRef<HTMLDivElement, RichTextProps>(
  ({ text, className }, ref) => {
    const styles = useStyles();
    return (
      <div
        ref={ref}
        className={cx(className, styles.richText)}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  },
);
