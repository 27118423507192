import {
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import PoweredByGoogle from './powered_by_google_on_white.png';
import { useStyles } from './styles';
import { LoadedSuggestions } from './useSuggestedAddress';

export interface MenuProps {
  suggestions: LoadedSuggestions;
  onSelect: (placeId: string) => void;
  onClickAway: () => void;
  open: boolean;
}

export const SuggestionMenu: React.VFC<MenuProps> = ({
  suggestions,
  onSelect,
  onClickAway,
  open,
}) => {
  const handleKeyNav = useCallback(
    (event: React.KeyboardEvent) => {
      const { key } = event;
      const target = event.target as HTMLElement;
      // eslint-disable-next-line default-case
      switch (key) {
        case 'ArrowUp':
          (target.previousElementSibling as HTMLDivElement | null)?.focus();
          break;
        case 'ArrowDown':
          (target.nextElementSibling as HTMLDivElement | null)?.focus();
          break;
        case 'Escape':
          onClickAway();
          break;
      }
    },
    [onClickAway],
  );

  const styles = useStyles();
  if (suggestions.status === 'idle' || !open) {
    return null;
  }
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <List
        className={styles.suggestionMenu}
        role="listbox"
        id="address-listbox"
        onKeyUp={handleKeyNav}
      >
        {suggestions.status === 'loading' && (
          <ListItem>
            <FormattedMessage
              defaultMessage="Loading..."
              description="Loading text of Google map suggestion menu when users start entering their address"
            />
          </ListItem>
        )}
        {suggestions.status === 'error' && (
          <ListItem>
            <FormattedMessage
              defaultMessage="Failed to load suggestions..."
              description="Loading failure message of Google map suggestion menu"
            />
          </ListItem>
        )}
        {suggestions.status === 'ready' &&
          suggestions.data.predictions.map(prediction => (
            <ListItem
              key={prediction.place_id}
              button
              role="option"
              onClick={() => onSelect(prediction.place_id)}
            >
              <ListItemText
                primary={prediction.structured_formatting.main_text}
                secondary={prediction.structured_formatting.secondary_text}
              />
            </ListItem>
          ))}
        <ListItem>
          <img alt="Powered by Google" src={PoweredByGoogle} />
        </ListItem>
      </List>
    </ClickAwayListener>
  );
};
