import { ApplicantPortalService, FileUpload } from 'api-clients/monolith';
import invariant from 'invariant';
import Dropzone, { IFileWithMeta } from 'react-dropzone-uploader';

interface BlobDetails {
  [key: string]: string | number;
}

export const buildUniqueName = (
  filename: string,
  dataFieldId: number,
): string => {
  return `${dataFieldId}-${filename}`;
};

export const fileWithUniqueName = (file: File, dataFieldId: number): File => {
  const newFile = new File([file], buildUniqueName(file.name, dataFieldId));
  Object.defineProperty(newFile, 'size', { value: file.size });
  Object.defineProperty(newFile, 'type', { value: file.type });

  return newFile;
};

export const getUploadParams =
  (
    uploadFields: FileUpload | undefined,
    dataFieldId: number,
  ): React.ComponentProps<typeof Dropzone>['getUploadParams'] =>
  ({ file }) => {
    const body = new FormData();
    const formDataEntries = Object.entries(uploadFields?.form_data ?? {});
    const url = uploadFields?.url ?? '';
    const uniqueFile = fileWithUniqueName(file, dataFieldId);

    formDataEntries.forEach(entry => body.append(entry[0], entry[1]));
    body.append('Content-Type', file.type);
    body.append('file', uniqueFile);

    return {
      url,
      body,
    };
  };

export const getStorageKey = (
  storageProvider: 'azure' | 'AWS',
  formDataKey: string,
  filename: string,
  dataFieldId: number,
): string => {
  const filenameVar =
    storageProvider === 'AWS' ? '${filename}' : '$$$filename$$$'; // eslint-disable-line no-template-curly-in-string
  return formDataKey.replace(
    filenameVar,
    buildUniqueName(filename, dataFieldId),
  );
};

export const getBlobDetails = (
  fileWithMeta: IFileWithMeta,
  uploadFields: FileUpload,
  dataFieldId: number,
) => {
  const filename = fileWithMeta.file.name;
  const storageProvider = uploadFields.storage_provider;
  const key = fileWithMeta.xhr?.responseXML?.querySelector('Key')?.textContent;
  invariant(key, 'S3 key was null');
  const storageKey = getStorageKey(storageProvider, key, filename, dataFieldId);

  const blobDetails = {
    blob_id: storageKey,
    blob_public_url:
      uploadFields && storageKey
        ? encodeURI(`${uploadFields?.url}/${storageKey}`)
        : '',
    blob_size: fileWithMeta.file.size,
    storage_provider: storageProvider,
    filename,
  };

  return blobDetails;
};

export const setDoneInput = (
  file: IFileWithMeta | undefined,
  valInput: HTMLInputElement,
  uploadFields: FileUpload,
  dataFieldId: number,
): BlobDetails | null => {
  let valObj = { blob_public_url: '' };
  if (uploadFields && file) {
    valObj = getBlobDetails(file, uploadFields, dataFieldId);

    valInput.setAttribute(
      'value',
      JSON.stringify({ ...valObj, data_field_id: dataFieldId }),
    );
    return valObj;
  }
  valInput.removeAttribute('value');
  return null;
};

export const getSecureDataUrl = async (
  file: IFileWithMeta,
  uploadFields: FileUpload,
  accountSlug: string,
  applicantExternalId: string,
  dataFieldId: number,
) => {
  const blobDetails = getBlobDetails(
    file,
    uploadFields as unknown as FileUpload,
    dataFieldId,
  );

  const query = JSON.stringify({
    data: blobDetails,
    storage: 'SecureUploadsStorage',
  });

  const secureDataUrl =
    await ApplicantPortalService.getInternalApiPortalApplicationsBlobPublicUrl(
      accountSlug,
      applicantExternalId,
      query,
    );

  return secureDataUrl.url;
};

export const runBlurryDetection = async (
  secureDataUrl: Promise<string>,
  lorgnetteEndpoint: string,
): Promise<boolean> => {
  const urlString = await secureDataUrl;
  const response = await fetch(lorgnetteEndpoint, {
    method: 'POST',
    headers: {
      Accept: 'application/json; charset=UTF-8',
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify({ image: urlString }),
  });

  if (response.ok) {
    const blurryCheck = (await response.json()) as unknown as {
      blurry: boolean;
      variant: number;
    };

    return blurryCheck.blurry;
  }

  const { error } = (await response.json()) as unknown as { error: string };
  throw new Error(error);
};
