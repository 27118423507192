import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    fieldContainer: {
      marginBottom: theme.spacing(6),
    },
    headerButtons: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: theme.spacing(2, 0),
      cursor: 'pointer',
    },
    headerText: {
      textDecoration: 'underline',
      fontWeight: 500,
    },
    selectAllText: {
      textDecoration: 'underline',
      fontWeight: 500,
    },
    checkboxGrid: {
      display: 'grid',
      alignItems: 'center',
      border: 'none',
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(-1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    withError: {
      '&:invalid': {
        borderRadius: 5,
        boxShadow: `0 0 1px 1px ${theme.palette.common.red400}`,
      },
    },
    rowBorder: {
      gridColumnStart: 1,
      gridColumnEnd: 6,
      borderBottom: theme.customBorders.gray300,
    },
    helperText: {
      bottom: 'auto',
      paddingTop: theme.spacing(1),
    },
  }),
);
