import { FormHelperText as MuiFormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

export const useFormHelperTextStyles = makeStyles(() => ({
  root: {
    fontSize: '1rem',
    marginLeft: 0,
    marginRight: 0,
  },
}));

type MuiProps = React.ComponentProps<typeof MuiFormHelperText>;

export type FormHelperTextProps = MuiProps;

export const FormHelperText: React.FC<FormHelperTextProps> = ({
  classes,
  ...rest
}) => {
  const overrideClasses = useFormHelperTextStyles();
  return (
    <MuiFormHelperText
      {...rest}
      classes={{ ...(classes ?? null), ...overrideClasses }}
    />
  );
};
