import { OpenAPI } from 'api-clients/monolith';
import React from 'react';
import ReactDOM from 'react-dom';

import { domainMeta } from 'utils/domainMeta';

import { getDirection, setBodyDirection } from './direction';
import { Root } from './root';
import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_ENABLED,
  DATADOG_SAMPLE_RATE,
  DATADOG_SITE,
  initializeDatadog,
} from './utils/datadog';

if (DATADOG_ENABLED === 'true') {
  initializeDatadog({
    allowedTracingOrigins: [domainMeta.monolithOrigin],
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    env: domainMeta.environment,
    sampleRate: DATADOG_SAMPLE_RATE, // Default to sampling nothing
    service: 'applicant-ui',
    site: DATADOG_SITE,
    tenant: domainMeta.tenant,
    version: process.env.SHORT_SHA,
  });
}

// Load Direction settings
const direction = getDirection();
setBodyDirection(direction);

// Setup OpenApi client
OpenAPI.BASE = domainMeta.monolithOrigin;

const MOUNT_NODE = document.getElementById('app');

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  MOUNT_NODE,
);
