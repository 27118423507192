import { Box, Button } from '@material-ui/core';
import { UnprocessableFields } from 'api-clients/monolith/models/UnprocessableFields';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ConsentVariant } from 'features/ApplicationForm/apiResponse';

import { domainMeta } from '../../../utils/domainMeta';
import { FormInput } from './FormInput';
import { useStyles } from './styles';
import { ProgressManagerProps as BaseProps } from './types';

export interface PreambleProps {
  showErrors: boolean;
  turnstileSiteKey: string;
  serverInvalidFields: UnprocessableFields | undefined;
}

export interface SubmitProps {
  onClick: React.MouseEventHandler;
  smsConsentVariant?: ConsentVariant;
}

const DefaultSubmit: React.VFC<SubmitProps> = ({ onClick }) => (
  <Box mt={8}>
    <Button
      fullWidth
      variant="contained"
      color="primary"
      type="submit"
      onClick={onClick}
    >
      <FormattedMessage
        defaultMessage="Submit"
        description="Submit button label for the progress manager"
      />
    </Button>
  </Box>
);

export interface ProgressManagerProps extends BaseProps {
  classNames?: {
    dataFieldContainer?: string;
  };
  Preamble?: React.ComponentType<PreambleProps>;
  Submit?: React.ComponentType<SubmitProps>;
  formProps?: JSX.IntrinsicElements['form'];
  inputName?: (dataKey: string) => string;
  isReachApplicationFormEnabled?: boolean;
}

const focusInvalid = (errors: string[]) => {
  if (errors.length === 0) {
    return;
  }
  const input: HTMLInputElement | null = document.querySelector(
    `input[name*=${errors[0]}],textarea[name*=${errors[0]}]`,
  );
  if (input) {
    input.focus();
    if (typeof input.scrollIntoView === 'function') {
      /* istanbul ignore next -- unreachable in jsdom */
      input.scrollIntoView({ block: 'center' });
    }
  }
};

export const ProgressManager: React.VFC<ProgressManagerProps> = ({
  classNames = {},
  dataFields,
  uploadFields,
  onSubmit,
  Preamble,
  Submit = DefaultSubmit,
  formProps,
  inputName,
  countryCode,
  serverInvalidFields,
  isReachApplicationFormEnabled,
}) => {
  const styles = useStyles();
  const [showErrors, setShowErrors] = useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    const form = event.target as HTMLFormElement;
    if (!form.checkValidity()) {
      event.preventDefault();
      const el = form.querySelector<HTMLElement>(
        'form input:invalid,textarea:invalid',
      );
      el?.focus();
      return;
    }

    onSubmit(new FormData(form), event);
  };

  useEffect(() => {
    if (serverInvalidFields) {
      focusInvalid(Object.keys(serverInvalidFields.errors));
    }
  }, [serverInvalidFields]);

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="on" {...formProps}>
      {Preamble && (
        <Preamble
          showErrors={showErrors}
          turnstileSiteKey={domainMeta.apiKey.turnstileSiteKey}
          serverInvalidFields={serverInvalidFields}
        />
      )}
      {dataFields.map(dataField => {
        if (dataField.type === 'hidden_field') {
          return (
            <FormInput
              dataField={dataField}
              key={dataField.key}
              inputName={inputName}
            />
          );
        }
        return (
          <Box
            className={cx(
              styles.dataFieldContainer,
              classNames.dataFieldContainer,
            )}
            key={dataField.key}
          >
            <FormInput
              dataField={dataField}
              uploadFields={uploadFields}
              showErrors={showErrors}
              inputName={inputName}
              dense={Boolean(Preamble)}
              countryCode={countryCode}
              isReachApplicationFormEnabled={isReachApplicationFormEnabled}
            />
          </Box>
        );
      })}
      <Submit onClick={() => setShowErrors(true)} />
    </form>
  );
};
