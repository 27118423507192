import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    buttonGroup: {
      display: 'flex',
      gap: '1rem',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
        gap: 0,
      },
      '& .MuiButtonBase-root': {
        [theme.breakpoints.up('md')]: {
          width: theme.spacing(25),
        },
      },
    },
    onlyOneButton: {
      justifyContent: 'flex-end',
    },
    dataFieldContainer: {
      marginBottom: theme.spacing(3),
    },
  }),
);
