import React from 'react';
import { useIntl } from 'react-intl';

import { TextField } from 'components/TextField';

interface BankFieldProps {
  required?: boolean;
  questionKey: string;
  shouldValidate?: boolean;
}

export const BankField = ({
  required,
  questionKey,
  shouldValidate,
}: BankFieldProps) => {
  const intl = useIntl();
  const inputName = `data_collection[${questionKey}]`;
  const validationMessage = intl.formatMessage({
    defaultMessage: 'Only numbers and must be between 5-17 digits',
    description: 'bank number prompt to ignore spaces and dashes',
  });

  return (
    <TextField
      id={questionKey}
      name={inputName}
      required={required}
      // only digits, not too short and not too long
      pattern="^\d{5,17}$"
      patternMismatchText={validationMessage}
      helperText={validationMessage}
      shouldValidate={shouldValidate}
      placeholder={validationMessage}
    />
  );
};

export default BankField;
