import { SvgIcon } from '@material-ui/core';
import React from 'react';

import type { SvgIconProps } from '@material-ui/core/SvgIcon';

export const Close: React.VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path d="M12.7071 3.29279C12.5196 3.10532 12.2652 3 12.0001 3C11.7349 3 11.4806 3.10532 11.2931 3.29279L8.00008 6.58579L4.70708 3.29279C4.51848 3.11063 4.26588 3.00983 4.00368 3.01211C3.74148 3.01439 3.49067 3.11956 3.30526 3.30497C3.11985 3.49038 3.01469 3.74119 3.01241 4.00339C3.01013 4.26558 3.11092 4.51818 3.29308 4.70679L6.58608 7.99979L3.29308 11.2928C3.19757 11.385 3.12139 11.4954 3.06898 11.6174C3.01657 11.7394 2.98898 11.8706 2.98783 12.0034C2.98668 12.1362 3.01198 12.2678 3.06226 12.3907C3.11254 12.5136 3.18679 12.6253 3.28069 12.7192C3.37458 12.8131 3.48623 12.8873 3.60913 12.9376C3.73202 12.9879 3.8637 13.0132 3.99648 13.012C4.12926 13.0109 4.26048 12.9833 4.38249 12.9309C4.50449 12.8785 4.61483 12.8023 4.70708 12.7068L8.00008 9.41379L11.2931 12.7068C11.4817 12.8889 11.7343 12.9897 11.9965 12.9875C12.2587 12.9852 12.5095 12.88 12.6949 12.6946C12.8803 12.5092 12.9855 12.2584 12.9878 11.9962C12.99 11.734 12.8892 11.4814 12.7071 11.2928L9.41408 7.99979L12.7071 4.70679C12.8946 4.51926 12.9999 4.26495 12.9999 3.99979C12.9999 3.73462 12.8946 3.48031 12.7071 3.29279Z" />
  </SvgIcon>
);
