import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { TextField } from '../TextField';

type MuiProps = React.ComponentProps<typeof TextField>;
export type TextFieldWithConfirmProps = MuiProps;

export const TextFieldWithConfirm: React.VFC<TextFieldWithConfirmProps> = ({
  shouldValidate,
  error,
  pattern,
  ...props
}) => {
  const intl = useIntl();
  const [fieldValue, setFieldValue] = useState({ main: '', confirm: '' });
  const handleChangeEvent = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const e = event;

      e?.persist();
      let mainValue = '';
      let confirmValue = '';
      e.currentTarget?.querySelectorAll('input').forEach(input => {
        if (input.dataset.confirm === 'true') {
          confirmValue = input.value;
        } else {
          mainValue = input.value;
        }
      });
      const valuesAreTheSame = mainValue === confirmValue;
      const confirmElement = e.currentTarget?.querySelector(
        '[data-confirm]',
      ) as HTMLInputElement;

      if (confirmElement) {
        confirmElement.setCustomValidity(
          !valuesAreTheSame ? 'The values must match' : '',
        );
      }

      setFieldValue({
        main: mainValue,
        confirm: confirmValue,
      });
    },
    [setFieldValue],
  );

  const valueMatchesRegex = useCallback(
    (inputValue: string) => {
      if (!pattern) return true;

      const regex = new RegExp(pattern);
      return regex.test(inputValue);
    },
    [pattern],
  );

  return (
    <div onChange={handleChangeEvent} data-textfieldwithconfirmroot>
      <label>
        <TextField
          {...props}
          pattern={pattern}
          required
          error={
            shouldValidate &&
            !valueMatchesRegex(fieldValue.main) &&
            error &&
            fieldValue.main.length === 0
          }
          value={fieldValue.main}
        />
      </label>

      <label>
        <TextField
          {...props}
          id={`${props.id}-confirm`}
          pattern={pattern}
          required
          placeholder={intl.formatMessage({
            defaultMessage: 'Please confirm text here',
            description: 'Placeholder text for Confirm input',
          })}
          value={fieldValue.confirm}
          error={
            shouldValidate &&
            (fieldValue.main !== fieldValue.confirm ||
              (fieldValue.main.length === 0 &&
                fieldValue.confirm.length === 0) ||
              !valueMatchesRegex(fieldValue.confirm))
          }
          inputProps={{
            'data-confirm': true,
          }}
        />
      </label>
    </div>
  );
};
