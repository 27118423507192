import { SvgIcon } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import React from 'react';

export interface LoadingProps {
  className?: string;
  size?: number;
}

const useStyles = makeStyles<Theme, LoadingProps & { size: number }>(() => ({
  loadingIcon: {
    '--size': props => `${props.size}px`,
    height: 'var(--size)',
    width: 'var(--size)',
    animation: '1s linear 0s infinite normal',
    animationName: '$loadingSpinner',
  },
  '@keyframes loadingSpinner': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

export const Loading: React.VFC<LoadingProps> = ({ className, size = 48 }) => {
  const styles = useStyles({ size });

  return (
    <SvgIcon
      titleAccess="Loading Icon"
      fontSize="large"
      viewBox="0 0 48 48"
      className={cx(className, styles.loadingIcon)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45ZM24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
        fill="#D6D9E4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 24H48C48 10.7452 37.2548 0 24 0V3C35.598 3 45 12.402 45 24Z"
        fill="#48A0F7"
      />
    </SvgIcon>
  );
};
