import {
  SPEED_OF_ME_API_KEY,
  SPEED_OF_ME_DEV_API_KEY,
} from '../features/TechCheckStage/vendor/speedofme/constants';
import {
  GOOGLE_MAPS_DEVELOPMENT_API_KEY,
  GOOGLE_MAPS_PRODUCTION_API_KEY,
  GOOGLE_MAPS_STAGING_API_KEY,
} from './google/constants';
import {
  TURNSTILE_PRODUCTION_SITE_KEY,
  TURNSTILE_STAGING_SITE_KEY,
} from './turnstile/constants';

interface DomainMeta {
  environment: 'dev' | 'stage' | 'uat' | 'prod';
  monolithOrigin: string;
  tenant: string;
  apiKey: {
    googleMaps: string;
    speedOfMe: string;
    turnstileSiteKey: string;
  };
  widgetBaseUrl: string;
}

// Searches for any base domain such as web.fountain.com. Will not match `app.web.fountain.com`.
const FOUNTAIN_DOMAIN_MATCHER = /^[^.]*\.fountain\.com/;
// Matches domains like "sandbox.amazon-na.fountain.com"
const FOUNTAIN_SANDBOX_SUBDOMAIN_MATCHER =
  /^sandbox\.[a-zA-Z0-9-]+\.fountain\.com/;
// Matches the staging domains such as stage-use-11.fountain.com
const FOUNTAIN_STAGING_DOMAIN_MATCHER = /^staging[^.]*\.fountain\.com/;

const FOUNTAIN_DEV_DOMAINS = [
  'dev-01.fountain.com',
  'dev-02.fountain.com',
  'faut-02.fountain.com',
];

const FOUNTAIN_UAT_DOMAINS = ['uat-01.fountain.com', 'faut-01.fountain.com'];

const isStagingDomain = (hostname: string) => {
  if (FOUNTAIN_STAGING_DOMAIN_MATCHER.test(hostname)) {
    return true;
  }
  return false;
};

const isUatDomain = (hostname: string) => {
  if (FOUNTAIN_UAT_DOMAINS.includes(hostname)) {
    return true;
  }
  return false;
};

const isDevDomain = (hostname: string) => {
  if (FOUNTAIN_DEV_DOMAINS.includes(hostname)) {
    return true;
  }
  return false;
};

const isSandboxDomain = (hostname: string): boolean =>
  FOUNTAIN_SANDBOX_SUBDOMAIN_MATCHER.test(hostname);

export const inferDomainMeta = (hostname: string): DomainMeta => {
  if (hostname === 'localhost') {
    return {
      environment: 'dev',
      monolithOrigin: `http://localhost:${
        process.env.NODE_ENV === 'test' ? 3023 : 3000
      }`,
      tenant: 'local',
      apiKey: {
        googleMaps: GOOGLE_MAPS_DEVELOPMENT_API_KEY,
        speedOfMe: SPEED_OF_ME_DEV_API_KEY,
        turnstileSiteKey: TURNSTILE_STAGING_SITE_KEY,
      },
      widgetBaseUrl: 'http://localhost:5555',
    };
  }

  if (hostname.endsWith('.chromatic.com')) {
    return {
      environment: 'dev',
      monolithOrigin: 'http://localhost:3000',
      tenant: 'storybook',
      apiKey: {
        googleMaps: GOOGLE_MAPS_DEVELOPMENT_API_KEY,
        speedOfMe: SPEED_OF_ME_DEV_API_KEY,
        turnstileSiteKey: TURNSTILE_STAGING_SITE_KEY,
      },
      widgetBaseUrl: 'http://localhost:5555',
    };
  }

  if (isDevDomain(hostname)) {
    return {
      environment: 'dev',
      monolithOrigin: `https://${hostname}`,
      tenant: hostname.split('.')[0],
      apiKey: {
        googleMaps: GOOGLE_MAPS_STAGING_API_KEY,
        speedOfMe: SPEED_OF_ME_DEV_API_KEY,
        turnstileSiteKey: TURNSTILE_STAGING_SITE_KEY,
      },
      widgetBaseUrl: `https://widget.${hostname}`,
    };
  }

  if (isStagingDomain(hostname)) {
    return {
      environment: 'stage',
      monolithOrigin: `https://${hostname}`,
      tenant: hostname.split('.')[0],
      apiKey: {
        googleMaps: GOOGLE_MAPS_STAGING_API_KEY,
        speedOfMe: SPEED_OF_ME_API_KEY,
        turnstileSiteKey: TURNSTILE_STAGING_SITE_KEY,
      },
      widgetBaseUrl: `https://widget.${hostname}`,
    };
  }

  if (isUatDomain(hostname)) {
    return {
      environment: 'uat',
      monolithOrigin: `https://${hostname}`,
      tenant: hostname.split('.')[0],
      apiKey: {
        googleMaps: GOOGLE_MAPS_STAGING_API_KEY,
        speedOfMe: SPEED_OF_ME_API_KEY,
        turnstileSiteKey: TURNSTILE_STAGING_SITE_KEY,
      },
      widgetBaseUrl: `https://widget.${hostname}`,
    };
  }

  if (isSandboxDomain(hostname)) {
    const subdomain = hostname.split('.')[1];

    return {
      environment: 'prod',
      monolithOrigin: `https://${hostname}`,
      tenant: subdomain,
      apiKey: {
        googleMaps: GOOGLE_MAPS_PRODUCTION_API_KEY,
        speedOfMe: SPEED_OF_ME_API_KEY,
        turnstileSiteKey: TURNSTILE_PRODUCTION_SITE_KEY,
      },
      widgetBaseUrl:
        hostname === 'web.fountain.com'
          ? `https://widget.fountain.com`
          : `https://widget.${hostname}`,
    };
  }

  if (FOUNTAIN_DOMAIN_MATCHER.test(hostname)) {
    const subdomain = hostname.split('.')[0];

    return {
      environment: 'prod',
      monolithOrigin: `https://${hostname}`,
      tenant: subdomain,
      apiKey: {
        googleMaps: GOOGLE_MAPS_PRODUCTION_API_KEY,
        speedOfMe: SPEED_OF_ME_API_KEY,
        turnstileSiteKey: TURNSTILE_PRODUCTION_SITE_KEY,
      },
      widgetBaseUrl:
        hostname === 'web.fountain.com'
          ? `https://widget.fountain.com`
          : `https://widget.${hostname}`,
    };
  }

  throw new Error('Unable to map hostname to a known domain');
};

export const domainMeta = inferDomainMeta(window.location.hostname);
