import { Radio as MuiRadio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 1.25, 0, 1.75),
    '&:hover': { backgroundColor: 'transparent' },
  },
}));

type MuiProps = React.ComponentProps<typeof MuiRadio>;

export type RadioProps = Omit<MuiProps, 'color'>;

export const Radio: React.VFC<RadioProps> = ({ classes, ...rest }) => {
  const overrideClasses = useStyles();
  return (
    <MuiRadio
      {...rest}
      color="default"
      classes={{ ...(classes ?? null), ...overrideClasses }}
    />
  );
};
