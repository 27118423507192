import { DataFields } from 'api-clients/monolith';
import invariant from 'invariant';
import { useRef } from 'react';

type LogicJumpList = DataFields[number]['logic_jumps'];
type TriggeredLogicJump = {
  operator: string;
  values?: string[] | undefined;
  /* eslint-disable camelcase */
  jump_to_next_stage: boolean;
  jump_to_data_field_id?: number | undefined;
  /* eslint-enable camelcase */
};

// Utility to narrow element type by input.
const verifiedTypeElement: (
  currentField: HTMLElement,
  dataFieldType: DataFields[number]['type'],
) => HTMLTextAreaElement | HTMLInputElement = (currentField, dataFieldType) => {
  switch (dataFieldType) {
    case 'text_area':
      invariant(
        currentField instanceof HTMLTextAreaElement,
        '`currentField` is not an HTMLTextAreaElement as expected',
      );
      break;
    default:
      invariant(
        currentField instanceof HTMLInputElement,
        '`currentField` is not an HTMLInputElement as expected',
      );
      break;
  }

  return currentField;
};

const CHECKED_QUESTION_TYPES: string[] = [
  'radio',
  'checkboxes',
  'availability',
];
const LOGIC_JUMP_VALUE_COMPARABLE_TYPES: string[] = ['dropdown', 'radio'];

/**
 * Search the 'currentJumpList' array for the index of a
 * Logic Jump with 'operator' criteria matching the 'currentField'
 * response. Return the matching Logic Jump or false to indicate no
 * match was found.
 */
export const detectTriggered: (
  currentField: HTMLElement,
  currentJumpList: LogicJumpList,
  dataFieldType: DataFields[number]['type'],
) => TriggeredLogicJump | false = (
  currentField,
  currentJumpList,
  dataFieldType,
) => {
  if (!(currentJumpList && currentJumpList.length)) return false;

  const triggeringIndex: number = currentJumpList.findIndex(jump => {
    const { operator } = jump;

    let inputValue: string | undefined;
    /**
     * For 'checkboxes' and 'availability' questions we only need to query for
     * any single checked input because they only map to the EXISTS operator.
     */
    if (CHECKED_QUESTION_TYPES.includes(dataFieldType)) {
      const maybeCheckedInput =
        currentField.querySelector<HTMLInputElement>('input:checked');
      inputValue = maybeCheckedInput?.value;
    } else {
      inputValue = verifiedTypeElement(currentField, dataFieldType).value;
    }

    if (!inputValue) return false;

    const compareWithValues =
      LOGIC_JUMP_VALUE_COMPARABLE_TYPES.includes(dataFieldType);
    const jumpVals = jump.values!;
    switch (operator) {
      case 'EXISTS':
        if (inputValue.length > 0) {
          return true;
        }
        break;
      case 'INCLUDES_ANY':
        if (compareWithValues && jumpVals.includes(inputValue)) {
          return true;
        }
        break;
      case 'DOES_NOT_INCLUDE':
        if (compareWithValues && !jumpVals.includes(inputValue)) {
          return true;
        }
        break;
      default:
        break;
    }

    return false;
  });

  return currentJumpList[triggeringIndex] !== undefined
    ? currentJumpList[triggeringIndex]
    : false;
};

/**
 * Search dataFields to return the index of the next expected
 * question as defined by the 'jump_to_data_field_id' on the
 * triggered Logic Jump.
 */
export const setJumpToIndex: (
  dataFields: DataFields,
  destinationId: TriggeredLogicJump['jump_to_data_field_id'],
) => number = (dataFields, destinationId) => {
  const jumpToIndex: number = dataFields.findIndex(
    field => field.id === destinationId,
  );
  return jumpToIndex;
};

interface JumpDestination {
  submit: boolean;
  jumpToIndex: number;
}

export const getVisibleFields = (dataFields: DataFields) => {
  return dataFields.filter(dataField => {
    const isPredefinedBic = dataField.key === 'bic' && dataField.predefined;
    return dataField.type !== 'hidden_field' && !isPredefinedBic;
  });
};

export const useJumpUtils: () => {
  getJumpToDestination: (
    form: HTMLElement,
    currentInput: number,
    dataFields: DataFields,
  ) => JumpDestination;
  getJumpBackDestination: () => number;
  buildFormData: (form: HTMLFormElement) => FormData;
} = () => {
  // Acts as a stack to track the question order history
  const questionPath = useRef<number[]>([]);

  const getJumpToDestination: (
    form: HTMLElement,
    currentInput: number,
    dataFields: DataFields,
  ) => JumpDestination = (form, currentInput, dataFields) => {
    const currentJumpList = dataFields[currentInput]?.logic_jumps;
    const dataFieldType = dataFields[currentInput].type;
    const currentField = form.querySelector<HTMLElement>(
      `[id="${dataFields[currentInput].key}"]`,
    );
    invariant(currentField, '`currentField` element could not be found');
    const triggeredJump: TriggeredLogicJump | false = detectTriggered(
      currentField,
      currentJumpList,
      dataFieldType,
    );

    const visibleFields = getVisibleFields(dataFields);
    const submit: boolean = triggeredJump && triggeredJump.jump_to_next_stage;
    const jumpToIndex: number =
      triggeredJump && !submit
        ? setJumpToIndex(visibleFields, triggeredJump.jump_to_data_field_id)
        : currentInput + 1;

    questionPath.current.push(currentInput);
    return {
      submit,
      jumpToIndex,
    };
  };

  const getJumpBackDestination: () => number = () => {
    return questionPath.current.pop()!;
  };

  const buildFormData: (form: HTMLFormElement) => FormData = form => {
    const formData = new FormData(form);
    questionPath.current.forEach(idx => {
      formData.append(
        'data_collection[expected_field_indices][]',
        idx.toString(),
      );
    });
    return formData;
  };

  return {
    getJumpToDestination,
    getJumpBackDestination,
    buildFormData,
  };
};
