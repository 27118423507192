import { ApplicantPortalService } from 'api-clients/monolith';
import { CancelablePromise } from 'api-clients/monolith/core/CancelablePromise';
import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { useApiService } from '../useApiService';

export type CountriesResponse = Awaited<
  ReturnType<typeof ApplicantPortalService.getInternalApiPortalCountries>
>;

export type CountryStatesResponse = Awaited<
  ReturnType<typeof ApplicantPortalService.getInternalApiPortalCountriesStates>
>;

export const useCountries = () => {
  const countriesService = React.useCallback(() => {
    return ApplicantPortalService.getInternalApiPortalCountries();
  }, []);
  const { result } = useApiService<CountriesResponse, Redirect>(
    countriesService,
  );
  return { result };
};

export const useCountryStates = (countryCode?: string) => {
  const countryStatesService = React.useCallback(() => {
    if (!countryCode) {
      return new CancelablePromise<CountryStatesResponse>(resolve => {
        resolve([]);
      });
    }
    return ApplicantPortalService.getInternalApiPortalCountriesStates(
      countryCode,
    );
  }, [countryCode]);
  const { result } = useApiService<CountryStatesResponse, Redirect>(
    countryStatesService,
  );
  return { result };
};
