import React from 'react';

import { DisplayValidIcon } from '../DisplayValidIcon';
import { TextField } from '../TextField';
import { useStyles } from './styles';

type MuiProps = React.ComponentProps<typeof TextField>;
export type ShortAnswerProps = MuiProps & {
  validationStart: boolean;
  validationEnd: boolean;
};

export const ShortAnswer: React.VFC<ShortAnswerProps> = ({
  validationStart,
  validationEnd,
  required,
  ...props
}) => {
  const classes = useStyles();
  return (
    <TextField
      {...props}
      role="textbox"
      required={required}
      className={classes.shortAnswer}
      InputProps={{
        endAdornment: DisplayValidIcon({
          validationStart,
          validationEnd,
          id: '',
        }),
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') e.preventDefault();
      }}
    />
  );
};
