import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { DataFields } from 'api-clients/monolith';
import React, { useState, VFC } from 'react';

import { TextField, TextFieldProps } from '../TextField';
import { useStyles } from './styles';

type Options = DataFields[number]['options'];
type Option = NonNullable<Options>[number];

export interface DropdownFieldProps extends TextFieldProps {
  id: string;
  options: Options;
  required: boolean;
  helperText?: React.ReactNode;
  handleOptionClick?: (item: Option | null) => void;
  previouslySelected?: Option;
  value?: Option;
  /**
   * Can completely override any other `Autocomplete` props
   */
  autocompleteProps?: Partial<AutocompleteProps<Option>>;
}

export const DropdownField: VFC<DropdownFieldProps> = ({
  autocompleteProps,
  id,
  options,
  helperText = 'Search Error',
  name,
  previouslySelected,
  handleOptionClick,
  value,
  ...rest
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<Option | null>(
    options?.find(opt => opt.default) ?? previouslySelected ?? null,
  );

  const handleChange = (
    e: React.ChangeEvent<EventTarget>,
    value: Option | null,
  ) => {
    if (handleOptionClick) {
      handleOptionClick(value);
    }
    setSelected(value);
  };

  return (
    <>
      <Autocomplete
        id={`autocomplete_${id}`}
        fullWidth
        options={options ?? []}
        getOptionLabel={opt => opt.label}
        value={value ?? selected}
        data-testid="autocomplete-search"
        className={classes.dropdown}
        onChange={(e, value) => handleChange(e, value)}
        renderInput={params => (
          <TextField {...params} {...rest} helperText={helperText} />
        )}
        {...autocompleteProps}
      />
      {selected && (
        <input type="hidden" id={id} value={selected.value} name={name} />
      )}
    </>
  );
};
