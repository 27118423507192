import { makeStyles } from '@material-ui/core/styles';
import { FileUpload as FileUploadFields } from 'api-clients/monolith';
import cx from 'classnames';
import _ from 'lodash';
import React from 'react';

import {
  AllTheChildrenValues,
  HistoryFieldMeta,
  initialState,
  isAllTheChildrenValuesKey,
  reducer,
} from './reducer';
import { HistoryFieldView } from './View';

const useStyles = makeStyles(theme => ({
  historyField: {
    color: theme.palette.common.gray800,
    padding: theme.spacing(0, 1.25, 0, 1.75),
  },
}));

export interface HistoryFieldProps {
  inputName(key: string): string;
  className?: string;
  id: string;
  shouldValidate?: boolean;
  childField: 'address' | 'employment';
  required?: boolean;
  yearsOfValidation: string;
  uploadFields?: FileUploadFields;
  dataFieldId: number;
  allowFileUpload?: boolean;
  fileUploadRequired?: boolean;
  fileUploadHint?: string;
  additionalPartnerEmploymentQuestionsRequired?: boolean;
}

export const HistoryField: React.VFC<HistoryFieldProps> = ({
  inputName,
  className,
  id,
  shouldValidate,
  yearsOfValidation,
  childField,
  required,
  uploadFields,
  dataFieldId,
  allowFileUpload,
  fileUploadRequired,
  fileUploadHint,
  additionalPartnerEmploymentQuestionsRequired,
}) => {
  const styles = useStyles();
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    yearsOfValidation,
  });
  const validityInputRef = React.useRef<HTMLInputElement>(null);

  const extractName = (name: string): string => {
    const startAt = name.indexOf('[');
    if (startAt === -1) {
      const endAt = name.indexOf(']');
      return name.slice(0, endAt);
    }
    return extractName(name.slice(startAt + 1));
  };

  const checkDateValidity = (
    startMonth: number,
    startYear: number,
    endMonth: number,
    endYear: number,
    active: boolean,
  ) => {
    const startDate = new Date(startYear, startMonth - 1); // Months are zero-based in JavaScript (0 - 11)
    const endDate = new Date(endYear, endMonth - 1);

    const isStartMonthBeforeEndMonth = startDate <= endDate;
    const isStartYearBeforeEndYear =
      startDate.getFullYear() <= endDate.getFullYear();
    return (
      (isStartMonthBeforeEndMonth &&
        isStartYearBeforeEndYear &&
        isStartMonthBeforeEndMonth &&
        isStartYearBeforeEndYear) ||
      (startDate.toString() !== 'Invalid Date' && active)
    );
  };

  const checkChildComponentValidity = (form: HTMLFormElement) => {
    const tmpInputs = form
      .querySelector('#childComponent')
      ?.querySelectorAll('input');
    // const validationErrors = {};
    let anyErrorsOnChildComponent = false;
    if (tmpInputs) {
      tmpInputs.forEach(input => {
        if (!input.checkValidity()) {
          anyErrorsOnChildComponent = true;
        }
      });
    }

    const tmpFiles = form.querySelectorAll('.dzu-previewContainer');
    const uploadedFiles = form.querySelectorAll('#prevFiles');

    if (
      allowFileUpload &&
      fileUploadRequired &&
      _.isEmpty(tmpFiles) &&
      _.isEmpty(uploadedFiles)
    ) {
      anyErrorsOnChildComponent = true;
    }
    const tmpSelects = form
      .querySelector('#childComponent')
      ?.querySelectorAll('select');
    // const validationErrors = {};
    if (tmpSelects) {
      tmpSelects.forEach(select => {
        if (select.selectedIndex === 0) {
          if (
            select.name.includes('state_code') &&
            select.options.length === 1
          ) {
            return;
          }
          anyErrorsOnChildComponent = true;
        }
      });
    }
    return !anyErrorsOnChildComponent;
  };

  // any number 0 or less would indicate we have covered the numberOfYears for the question.
  // any positive number would indicate the number of missing years that should be gathered
  const differenceBetweenScopeAndRangeOfHistory = (
    histories: HistoryFieldMeta[],
    numberOfYears: string,
  ) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();

    histories.sort((a, b) => {
      if (a.startYear === b.startYear) {
        return a.startMonth - b.startMonth;
      }

      return a.startYear - b.startYear;
    });

    if (histories.length > 0) {
      if (currentMonth + 1 < histories[0].startMonth) {
        return (
          histories[0].startYear +
          1 -
          (currentYear - parseInt(numberOfYears, 10))
        ).toString();
      }
      return (
        histories[0].startYear -
        (currentYear - parseInt(numberOfYears, 10))
      ).toString();
    }
    return numberOfYears;
  };

  const forceRequired = () => {
    if (required) {
      return true;
    }
    return state.histories.length > 0;
  };

  React.useEffect(() => {
    if (validityInputRef.current) {
      validityInputRef.current.setCustomValidity('');
      validityInputRef.current.reportValidity();
    }
  }, [validityInputRef]);

  return (
    <div className={cx(className, styles.historyField)}>
      <HistoryFieldView
        uploadFields={uploadFields}
        dataFieldId={dataFieldId}
        allowFileUpload={allowFileUpload}
        fileUploadRequired={fileUploadRequired}
        fileUploadHint={fileUploadHint}
        inputName={inputName}
        id={id}
        state={state}
        required={forceRequired()}
        shouldValidate={shouldValidate}
        childField={childField}
        additionalPartnerEmploymentQuestionsRequired={
          additionalPartnerEmploymentQuestionsRequired
        }
        addHistory={() => {
          dispatch({
            type: 'AddHistoryAction',
            submitted: false,
            histories: state.histories,
            history: {
              startMonth: 0,
              startYear: 0,
              endMonth: 0,
              endYear: 0,
              active: false,
              covered: false,
              validDates: false,
              noHistory: false,
              childComponentValues: {},
            },
            numberOfYearsMissing: state.numberOfYearsMissing,
            yearsOfValidation,
            numberOfYearsRequiredMet: state.numberOfYearsRequiredMet,
          });
        }}
        addNoHistory={(history: HistoryFieldMeta) => {
          const newHistories = [
            ...state.histories,
            { ...history, noHistory: true, covered: true },
          ];
          const numberOfYearsMissing = differenceBetweenScopeAndRangeOfHistory(
            newHistories,
            yearsOfValidation,
          );

          dispatch({
            type: 'InvalidHistoryAction',
            histories: newHistories,
            submitted: true,
            numberOfYearsRequiredMet: !!(numberOfYearsMissing === '0'),
            numberOfYearsMissing,
            yearsOfValidation,
            childComponentValues: {},
          });
        }}
        confirmDelete={() => {
          const numberOfYearsMissing = differenceBetweenScopeAndRangeOfHistory(
            state.histories,
            yearsOfValidation,
          );

          dispatch({
            type: 'InvalidHistoryAction',
            histories: state.histories,
            submitted: false,
            numberOfYearsRequiredMet: !!(numberOfYearsMissing === '0'),
            numberOfYearsMissing,
            yearsOfValidation,
            childComponentValues: {},
          });
        }}
        deleteHistory={(history: HistoryFieldMeta) => {
          const allHistoriesMinusTheDeleteOne = state.histories.filter(
            localHistory => !_.isEqual(history, localHistory),
          );

          const numberOfYearsMissing = differenceBetweenScopeAndRangeOfHistory(
            allHistoriesMinusTheDeleteOne,
            yearsOfValidation,
          );

          dispatch({
            type: 'DeleteHistoryAction',
            histories: allHistoriesMinusTheDeleteOne,
            history,
            historyCopy: history,
            numberOfYearsRequiredMet: state.numberOfYearsRequiredMet ?? false,
            yearsOfValidation,
            numberOfYearsMissing,
          });
        }}
        discardHistoryChanges={() => {
          // If I have an address in state then I likely am in Edit mode
          if (state.historyCopy && state.historyCopy.covered) {
            const numberOfYearsMissing =
              differenceBetweenScopeAndRangeOfHistory(
                [...state.histories, state.historyCopy],
                yearsOfValidation,
              );

            dispatch({
              type: 'InvalidHistoryAction',
              histories: [...state.histories, state.historyCopy],
              submitted: false,
              numberOfYearsRequiredMet: !!(numberOfYearsMissing === '0'),
              numberOfYearsMissing,
              yearsOfValidation,
              childComponentValues: {},
            });
          } else {
            const numberOfYearsMissing =
              differenceBetweenScopeAndRangeOfHistory(
                [...state.histories],
                yearsOfValidation,
              );
            dispatch({
              type: 'InvalidHistoryAction',
              histories: state.histories,
              submitted: false,
              numberOfYearsRequiredMet: !!(numberOfYearsMissing === '0'),
              numberOfYearsMissing,
              yearsOfValidation,
              childComponentValues: {},
            });
          }
        }}
        editHistory={(history: HistoryFieldMeta) => {
          const allHistoriesMinusTheEditOne = state.histories.filter(
            localHistory => !_.isEqual(history, localHistory),
          );
          dispatch({
            type: 'EditHistoryAction',
            histories: allHistoriesMinusTheEditOne,
            history,
            historyCopy: history,
            numberOfYearsRequiredMet: state.numberOfYearsRequiredMet ?? false,
            numberOfYearsMissing: state.numberOfYearsMissing,
            yearsOfValidation,
          });
        }}
        inputChanged={(event: React.FormEvent) => {
          const form = (event.target as HTMLInputElement)
            .form as HTMLFormElement;
          const formData = new FormData(form);

          const validDates = checkDateValidity(
            parseInt(String(formData.get('startMonth')), 10),
            parseInt(String(formData.get('startYear')), 10),
            parseInt(String(formData.get('endMonth')), 10),
            parseInt(String(formData.get('endYear')), 10),
            !!formData.get('active'),
          );

          const currentHistory = {
            startMonth: parseInt(String(formData.get('startMonth')), 10),
            startYear: parseInt(String(formData.get('startYear')), 10),
            endMonth: parseInt(String(formData.get('endMonth')), 10),
            endYear: parseInt(String(formData.get('endYear')), 10),
            validDates,
            active: !!formData.get('active'),
            covered: true,
            noHistory: !!formData.get('noHistory'),
          };

          dispatch({
            type: 'AddHistoryAction',
            submitted: state.submitted,
            histories: state.histories,
            history: {
              ...currentHistory,
              childComponentValues: state.history?.childComponentValues ?? {},
            },
            yearsOfValidation,
            numberOfYearsMissing: state.numberOfYearsMissing,
            numberOfYearsRequiredMet: state.numberOfYearsRequiredMet,
            historyCopy: state.historyCopy,
          });
        }}
        saveHistory={(event: React.FormEvent) => {
          event.preventDefault();
          event.stopPropagation();
          const form = event.target as HTMLFormElement;
          const formData = new FormData(form);

          const validDates = checkDateValidity(
            parseInt(String(formData.get('startMonth')), 10),
            parseInt(String(formData.get('startYear')), 10),
            parseInt(String(formData.get('endMonth')), 10),
            parseInt(String(formData.get('endYear')), 10),
            !!formData.get('active'),
          );

          const newHistory = {
            ...state.history,
            startMonth: parseInt(String(formData.get('startMonth') ?? 0), 10),
            startYear: parseInt(String(formData.get('startYear') ?? 0), 10),
            endMonth: formData.get('active')
              ? 0
              : parseInt(String(formData.get('endMonth') ?? 0), 10),
            endYear: formData.get('active')
              ? 0
              : parseInt(String(formData.get('endYear') ?? 0), 10),
            validDates,
            active: !!formData.get('active'),
            covered: true,
            noHistory: !!formData.get('noHistory'),
            childComponentValues: {},
          };

          const validChildComponent = newHistory.noHistory
            ? true
            : checkChildComponentValidity(form);

          if (validDates && validChildComponent) {
            const numberOfYearsMissing =
              differenceBetweenScopeAndRangeOfHistory(
                [...state.histories, newHistory],
                yearsOfValidation,
              );

            const myInputs = form.querySelectorAll('input');
            const childValues: AllTheChildrenValues = {};
            if (!newHistory.noHistory) {
              myInputs.forEach(input => {
                if (input.type === 'hidden') {
                  const extractedName = extractName(input.name);

                  if (isAllTheChildrenValuesKey(extractedName)) {
                    childValues[extractedName] = input.value;
                  }
                }
              });
            }

            dispatch({
              type: 'InvalidHistoryAction',
              histories: [
                ...state.histories,
                {
                  ...newHistory,
                  childComponentValues: childValues,
                },
              ],
              submitted: false,
              numberOfYearsRequiredMet: !!(numberOfYearsMissing === '0'),
              numberOfYearsMissing,
              yearsOfValidation,
              childComponentValues: myInputs as AllTheChildrenValues,
            });
          } else {
            dispatch({
              type: 'AddHistoryAction',
              submitted: true,
              histories: state.histories,
              history: newHistory,
              numberOfYearsRequiredMet: state.numberOfYearsRequiredMet,
              numberOfYearsMissing: state.numberOfYearsMissing,
              yearsOfValidation,
            });
          }
        }}
      />
      {/* This exists to support logicJumps */}
      <input
        ref={validityInputRef}
        style={{ visibility: 'hidden' }}
        type="input"
        id={id}
        value="0101010"
      ></input>
    </div>
  );
};
