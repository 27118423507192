import { Grid, Typography } from '@material-ui/core';
import cx from 'classnames';
import { Accept, Warning } from 'icons/fds';
import React from 'react';
import { useIntl } from 'react-intl';

import { assertUnreachable } from 'utils/assertUnreachable';

import { useStyles } from './styles';

export type Severity = 'success' | 'error';

export interface ContentProps {
  severity: Severity;
  message: React.ReactNode;
}

interface SeverityIconProps {
  severity: Severity;
}

export const SeverityIcon: React.VFC<SeverityIconProps> = ({ severity }) => {
  const intl = useIntl();
  const styles = useStyles();

  switch (severity) {
    case 'error':
      return (
        <Warning
          aria-label={intl.formatMessage({
            defaultMessage: 'Warning Icon',
            description: 'Indicates an error',
          })}
          className={cx(styles.icon, styles.error)}
        />
      );
    case 'success':
      return (
        <Accept
          aria-label={intl.formatMessage({
            defaultMessage: 'Success/Accept Icon',
            description: 'Indicates success',
          })}
          className={cx(styles.icon, styles.success)}
        />
      );
    /* istanbul ignore next */
    default:
      return assertUnreachable(severity);
  }
};

export const Content: React.VFC<ContentProps> = ({ severity, message }) => {
  return (
    <Grid container direction="row" wrap="nowrap" justify="center">
      <SeverityIcon severity={severity} />
      <Typography variant="body2">{message}</Typography>
    </Grid>
  );
};
