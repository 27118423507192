import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Checkboxes } from 'components/Checkboxes';
import { TextField } from 'components/TextField';
import { Question } from 'features/Question';

interface PartnerConsentProps {
  id: string;
  required: boolean;
  inputTypes: Array<'checkbox' | 'signature'>;
  shouldValidate?: boolean | undefined;
  applicantFirstName?: string;
  applicantLastName?: string;
}

export const PartnerConsent: React.VFC<PartnerConsentProps> = ({
  id,
  required,
  inputTypes,
  shouldValidate,
  applicantFirstName,
  applicantLastName,
}: PartnerConsentProps) => {
  const intl = useIntl();

  const [signatureMatches, setSignatureMatches] = useState(false);

  return (
    <Box mb={4} key={id} className="partner-consent" id={id}>
      {inputTypes.includes('checkbox') && (
        <Checkboxes
          id={`${id}-acknowledge`}
          data-testid={id}
          baseName={`${id}-acknowledge`}
          required={required}
          options={[
            {
              label: 'Acknowledge',
              value: 'Acknowledge',
              default: false,
            },
          ]}
          shouldValidate={shouldValidate}
        />
      )}

      {inputTypes.includes('signature') && (
        <Question
          id={`${id}-signature`}
          data-testid={id}
          key={id}
          question={intl.formatMessage({
            defaultMessage: 'Sign to Acknowledge',
            description: 'Signature field title',
          })}
          dense
          required
        >
          <TextField
            id={`${id}-signature`}
            name={`${id}-signature`}
            shouldValidate={shouldValidate}
            placeholder={intl.formatMessage({
              defaultMessage: 'Signature Here',
              description: 'Placeholder text for an input',
            })}
            error={shouldValidate && !signatureMatches}
            onChange={event => {
              if (
                event.target.value.trim() ===
                `${applicantFirstName ?? ''} ${applicantLastName ?? ''}`
              ) {
                setSignatureMatches(true);
                event.target.setCustomValidity('');
              } else {
                setSignatureMatches(false);
                event.target.setCustomValidity(
                  'Signature must match applicant name',
                );
              }
            }}
            required
          />
        </Question>
      )}
    </Box>
  );
};
