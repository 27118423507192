import { Box, FormHelperText, Typography } from '@material-ui/core';
import cx from 'classnames';
import invariant from 'invariant';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Checkbox } from '../Checkbox';
import { withMuiError } from '../WithMuiError';
import { useStyles } from './styles';

const DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const SHIFTS = ['morning', 'afternoon', 'evening'];

// format full days for display
type FormattedDayType = { [key: string]: string };
// format shifts for display
type FormattedShiftType = { [key: string]: string };

export interface AvailabilityFieldProps {
  id: string;
  baseName: string;
  required: boolean;
  error?: boolean;
  onInvalid?: React.FormEventHandler<HTMLButtonElement>;
}

type AvailabilityState = { [key: string]: boolean };

const AvailabilityField: React.VFC<AvailabilityFieldProps> = ({
  id,
  baseName,
  required,
  error,
  onInvalid,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [selectAll, setSelectAll] = useState(true);
  const [checkedItems, setCheckedItems] = useState<AvailabilityState>({});
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const parentElement = useRef<HTMLDivElement>(null);

  const FORMATTED_DAYS: FormattedDayType = {
    monday: intl.formatMessage({
      defaultMessage: 'Mon',
      description: 'Long Abbreviations for Monday',
    }),
    tuesday: intl.formatMessage({
      defaultMessage: 'Tues',
      description: 'Long Abbreviations for Tuesday',
    }),
    wednesday: intl.formatMessage({
      defaultMessage: 'Wed',
      description: 'Long Abbreviations for Wednesday',
    }),
    thursday: intl.formatMessage({
      defaultMessage: 'Thurs',
      description: 'Long Abbreviations for Thursday',
    }),
    friday: intl.formatMessage({
      defaultMessage: 'Fri',
      description: 'Long Abbreviations for Friday',
    }),
    saturday: intl.formatMessage({
      defaultMessage: 'Sat',
      description: 'Long Abbreviations for Saturday',
    }),
    sunday: intl.formatMessage({
      defaultMessage: 'Sun',
      description: 'Long Abbreviations for Sunday',
    }),
  };

  const FORMATTED_SHIFTS: FormattedShiftType = {
    morning: intl.formatMessage({
      defaultMessage: 'Morning',
      description: 'Morning shift text',
    }),
    afternoon: intl.formatMessage({
      defaultMessage: 'Afternoon',
      description: 'Afternoon shift text',
    }),
    evening: intl.formatMessage({
      defaultMessage: 'Evening',
      description: 'Evening shift text',
    }),
  };

  // checking if the checked items is the max size and are all the same value to toggle the select/unselect button
  useEffect(() => {
    const maxSize = DAYS.length * SHIFTS.length;
    const checkedItemsValues = Object.values(checkedItems);
    const allEqual = checkedItemsValues.every(
      value => value === checkedItemsValues[0],
    );
    if (checkedItemsValues.length === maxSize && allEqual) {
      setSelectAll(!checkedItemsValues[0]);
    }
    if (!allEqual) setSelectAll(true);
  }, [selectAll, checkedItems]);

  const setNewCheckedItems = (
    selectedDayShift: string,
    dayShiftList: Array<string>,
    isDay: boolean,
    value: boolean,
  ) => {
    const newCheckedItems: AvailabilityState = {};

    dayShiftList.forEach(toSelect => {
      const dayShift = isDay
        ? `${selectedDayShift}_${toSelect}`
        : `${toSelect}_${selectedDayShift}`;
      newCheckedItems[dayShift] = value;
    });

    setCheckedItems({ ...checkedItems, ...newCheckedItems });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dayShift = event.target.value;
    const checked = !!checkedItems[dayShift];
    setCheckedItems({ ...checkedItems, [dayShift]: !checked });
  };

  const allAreSelected = (
    selectedDayShift: string,
    dayShiftList: Array<string>,
    isDay: boolean,
  ) => {
    let isChecked = true;
    dayShiftList.forEach(toSelect => {
      const dayShift = isDay
        ? `${selectedDayShift}_${toSelect}`
        : `${toSelect}_${selectedDayShift}`;
      if (!checkedItems[dayShift]) {
        isChecked = false;
      }
    });
    return isChecked;
  };

  const handleDayShiftClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    isDay: boolean,
  ) => {
    const dayShift = isDay
      ? event.currentTarget.dataset.day
      : event.currentTarget.dataset.shift;
    const dayShiftList = isDay ? SHIFTS : DAYS;

    invariant(dayShift, 'Day or Shift element could not be found');

    setNewCheckedItems(
      dayShift,
      dayShiftList,
      isDay,
      !allAreSelected(dayShift, dayShiftList, isDay),
    );
  };

  const setAllBoxes = (value: boolean) => {
    const newCheckedItems: AvailabilityState = {};

    DAYS.forEach(day => {
      SHIFTS.forEach(shift => {
        newCheckedItems[`${day}_${shift}`] = value;
      });
    });
    setCheckedItems({ ...checkedItems, ...newCheckedItems });
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      setAllBoxes(true);
      setSelectAll(false);
    } else {
      setAllBoxes(false);
      setSelectAll(true);
    }
  };

  const setAllInvalid = (nodes: NodeListOf<HTMLInputElement>) => {
    nodes.forEach(checkbox =>
      checkbox.setCustomValidity('You must select at least one option'),
    );
    setShowErrorMessage(true);
  };

  const setAllValid = (nodes: NodeListOf<HTMLInputElement>) => {
    nodes.forEach(checkbox => checkbox.setCustomValidity(''));
    setShowErrorMessage(false);
  };
  const checkboxes = () =>
    (parentElement.current?.querySelectorAll('input[type="checkbox"]') ??
      /* istanbul ignore next -- just appeases TypeScript, won't occur in practice */
      []) as NodeListOf<HTMLInputElement>;

  const handleValidity = useCallback(() => {
    const boxes = checkboxes();
    if (Object.values(checkedItems).includes(true)) {
      setAllValid(boxes);
    } else {
      setAllInvalid(boxes);
    }
  }, [checkedItems]);

  useEffect(() => {
    if (required) {
      handleValidity();
    } else {
      setAllValid(checkboxes());
    }
  }, [required, handleValidity]);

  return (
    <div id={id} className={classes.fieldContainer} ref={parentElement}>
      <button
        type="button"
        onClick={toggleSelectAll}
        className={classes.headerButtons}
      >
        <Typography className={classes.selectAllText}>
          {selectAll ? (
            <FormattedMessage
              defaultMessage="Select All"
              description="Button text to inform users that all boxes will be checked after clicking on it"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Unselect All"
              description="Button text to inform users that all boxes will be unchecked after clicking on it"
            />
          )}
        </Typography>
      </button>
      <fieldset
        className={cx(classes.checkboxGrid, { [classes.withError]: error })}
      >
        <div />
        {SHIFTS.map(shift => (
          <button
            type="button"
            onClick={event => handleDayShiftClick(event, false)}
            data-shift={shift}
            className={classes.headerButtons}
            key={shift}
          >
            <Typography className={classes.headerText}>
              {FORMATTED_SHIFTS[shift]}
            </Typography>
          </button>
        ))}
        <div />
        <div className={classes.rowBorder} />
        {DAYS.map(day => (
          <React.Fragment key={day}>
            <div>
              <button
                type="button"
                onClick={event => handleDayShiftClick(event, true)}
                data-day={day}
                className={classes.headerButtons}
              >
                <Typography className={classes.headerText}>
                  {FORMATTED_DAYS[day]}
                </Typography>
              </button>
            </div>
            {SHIFTS.map(shift => (
              <Box textAlign="center" key={`${day}-${shift}-checkbox`}>
                <Checkbox
                  onChange={handleChange}
                  name={`${baseName}[]`}
                  checked={!!checkedItems[`${day}_${shift}`]}
                  onInvalid={onInvalid}
                  value={`${day}_${shift}`}
                  inputProps={{ title: `${day} ${shift}` }}
                />
              </Box>
            ))}
            <div />
            <div className={classes.rowBorder} />
          </React.Fragment>
        ))}
      </fieldset>
      <FormHelperText
        className={classes.helperText}
        component={Typography}
        error={error && showErrorMessage}
      >
        {error && showErrorMessage ? (
          <FormattedMessage
            defaultMessage="Please enter a value"
            description="Error message body when a user submits the question with an empty value"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Tap days or shifts to select multiple"
            description="Helper message for users to select/tap a box"
          />
        )}
      </FormHelperText>
    </div>
  );
};

const ValidatedAvailabilityField = withMuiError(AvailabilityField);

export { ValidatedAvailabilityField as AvailabilityField };
