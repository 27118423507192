import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    aliasRoot: {
      width: '100%',
      '& label': {
        marginBottom: theme.spacing(2),
      },
      '& p': {
        marginBottom: theme.spacing(1),
      },
      '& .MuiOutlinedInput-root': {
        marginBottom: 0,
      },
    },
    hideFields: {
      position: 'absolute',
      left: -5000,
    },
    button: {
      padding: 0,
    },
    clearButton: {
      marginTop: theme.spacing(3),
      padding: 0,
    },
  }),
);
