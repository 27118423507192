import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    addRemoveAddressContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      gap: '10px',
    },
    activeCheckboxContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    activeCheckbox: {
      '& span': {
        paddingLeft: 0,
      },
    },
    addressHeader: {
      marginBottom: theme.spacing(2),
    },
  }),
);
