import { Box } from '@material-ui/core';
import React from 'react';

import { Typography } from '../Typography';
import { useStyles } from './styles';

export interface QuestionLayoutProps {
  input: React.ReactNode;
  question: React.ReactNode;
  hint?: React.ReactNode;
  dense?: boolean;
}

export const QuestionLayout: React.VFC<QuestionLayoutProps> = ({
  input,
  question,
  hint,
  dense,
}) => {
  const styles = useStyles();

  return (
    <>
      <Box mb={dense ? 1 : 2} display="flex" alignItems="center" gridGap={8}>
        {question}
      </Box>
      {hint && (
        <Box mb={dense ? 3 : 4}>
          <Typography className={styles.hintText}>{hint}</Typography>
        </Box>
      )}
      {input}
    </>
  );
};
