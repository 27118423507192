import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  questionText: {
    color: theme.palette.common.black,
  },
  required: {
    '&:after': {
      color: theme.palette.common.red400,
      content: "' *'",
    },
  },
}));
