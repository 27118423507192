import { datadogLogs } from '@datadog/browser-logs';
import { LogsEvent } from '@datadog/browser-logs/cjs/logsEvent.types';
import {
  RumErrorEvent,
  RumEvent,
} from '@datadog/browser-rum-core/cjs/rumEvent.types';

import { THIRD_PARTY_SCRIPT_INJECTORS } from '../../scriptInjectors';

type BeforeSendFn<Event> = (event: Event) => void | boolean;

export const createBeforeSend = <Event>(
  middlewares: BeforeSendFn<Event>[] = [],
) => {
  if (middlewares.length > 0 === false) {
    return undefined;
  }

  // N.B. ANY of the provided middlewares can discard an event—along with any
  // modifications from other middlewares! This is because Datadog's
  // `beforeSend` itself is all-or-nothing.
  return (event: Event) =>
    middlewares.map(mw => mw(event)).some(v => v === false) ? false : undefined;
};

/**
 * Creates a before send function which filters out forwarded error events if
 * the predicate function passes
 */
const filterForwardedErrorEventIf = (
  predicate: (error: LogsEvent['error']) => boolean,
): BeforeSendFn<LogsEvent> => {
  return (event: LogsEvent) => {
    if (event.origin === 'source' && event.error && predicate(event.error)) {
      return false;
    }
    return undefined;
  };
};

/**
 * Creates a before send function which filters out unhandled error events if
 * the predicate function passes
 */
const filterUnhandledErrorEventIf = (
  predicate: (error: RumErrorEvent['error']) => boolean,
): BeforeSendFn<RumEvent> => {
  return (event: RumEvent) => {
    if (
      event.type === 'error' &&
      event.error.handling === 'unhandled' &&
      predicate(event.error)
    ) {
      return false;
    }
    return undefined;
  };
};

/**
 * Determines if a line of a stack call (sliced from a full trace) originates
 * from third-party code
 */
const isStackCallFromThirdParty = (stackCall: string): boolean => {
  const ownSourceFilePathPrefix =
    window.location.hostname ?? 'webpack-internal:///';
  return (
    stackCall.includes(ownSourceFilePathPrefix) === false ||
    THIRD_PARTY_SCRIPT_INJECTORS.some(injector => stackCall.includes(injector))
  );
};

/**
 * Determines if an error originates from third-party code
 *
 * N.B. Inspecting only the first call in the stack is both potentially brittle
 * (assumes all browser traces conform to a standard format) and not inclusive
 * of all third-party errors. It's just the most consistently available piece
 * of metadata available client-side.
 */
export const isThirdPartyJsError = (
  error: LogsEvent['error'] | RumErrorEvent['error'],
): boolean => {
  if (
    // LogsEvent['error'] can be `undefined`
    error === undefined ||
    // `LogEvent` uses `error.origin` while `RumEvent` uses `error.source`
    [error.origin, error.source].includes('source') === false
  ) {
    return false;
  }
  const maybeFirstStackCall = (error.stack?.split(' at ')[1] ?? '').trim();
  return maybeFirstStackCall
    ? isStackCallFromThirdParty(maybeFirstStackCall)
    : false;
};

/**
 * Redirect third party (customer) errors to warning logs
 *
 * N.B. `@datadog/browser-logs`'s `forwardErrorsToLogs` option will still
 * happily forward these errors without a `beforeSend` of its own!
 */
export const logWarningOnThirdPartyJsError: BeforeSendFn<RumEvent> = event => {
  if (
    event.type === 'error' &&
    event.error.handling === 'unhandled' &&
    isThirdPartyJsError(event.error)
  ) {
    datadogLogs.logger.warn(
      `Third-party JavaScript has thrown an error: ${event.error.message}`,
      {
        error: event.error,
      },
    );
    return false;
  }
  return undefined;
};

/**
 * Filter out third party (customer) forwarded errors
 *
 * Necessary on the Logs side to avoid `forwardErrorsToLogs` resulting in the
 * error still getting logged alongside the warning from
 * `logWarningOnThirdPartyJsError`
 */
export const filterThirdPartyForwardedJsErrors: BeforeSendFn<LogsEvent> =
  filterForwardedErrorEventIf(isThirdPartyJsError);

/**
 * Determines if an error is a CSP violation report
 */
const isCspReportError = (error: RumErrorEvent['error']): boolean => {
  return error.source === 'report' && error.message.includes('csp_violation');
};

/**
 * Filter out CSP violation reports
 */
export const filterCspReportErrors: BeforeSendFn<RumEvent> =
  filterUnhandledErrorEventIf(isCspReportError);

/**
 * Determines if an error relates to the Vungle ad network
 */
const isVungleError = (error: RumErrorEvent['error']): boolean => {
  return error.source === 'source' && error.message.includes('vungle');
};

/**
 * Filter out error events relating to the ad network Vungle
 */
export const filterVungleErrors: BeforeSendFn<RumEvent> =
  filterUnhandledErrorEventIf(isVungleError);
