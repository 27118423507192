export type AllTheChildrenValues = {
  // ignoring camelcase since these correspond to the name the back end is expecting
  /* eslint-disable camelcase */
  street_address?: string;
  address_2?: string;
  city?: string;
  state_code?: string;
  state_code_iso?: string;
  state_code_name?: string;
  postal_code?: string;
  country_code?: string;
  country_code_iso?: string;
  latitude?: string;
  longitude?: string;
  current_employment?: string;
  employer_name?: string;
  title?: string;
  employer_contact_name?: string;
  files?: string;
  designation?: string;
  ctc?: string;
  employee_code?: string;
  reason_for_leaving?: string;
  rm_designation?: string;
  rm_name?: string;
  rm_organization?: string;
  rm_contact?: string;
};
/* eslint-enable camelcase */

export const isAllTheChildrenValuesKey = (
  slug: string,
): slug is keyof AllTheChildrenValues => {
  return [
    'street_address',
    'address_2',
    'city',
    'state_code',
    'state_code_iso',
    'state_code_name',
    'postal_code',
    'country_code',
    'country_code_iso',
    'latitude',
    'longitude',
    'current_employment',
    'employer_name',
    'title',
    'employer_contact_name',
    'files',
    'designation',
    'ctc',
    'employee_code',
    'reason_for_leaving',
    'rm_designation',
    'rm_name',
    'rm_organization',
    'rm_contact',
  ].includes(slug);
};

export type HistoryFieldMeta = {
  startMonth: number;
  startYear: number;
  endMonth: number;
  endYear: number;
  active: boolean;
  covered: boolean;
  validDates: boolean;
  noHistory: boolean;
  childComponentValues: AllTheChildrenValues;
};

type InitState = {
  status: 'InitState';
  histories: HistoryFieldMeta[];
  history?: HistoryFieldMeta;
  historyCopy?: HistoryFieldMeta;
  numberOfYearsRequiredMet: boolean;
  numberOfYearsMissing: string;
  yearsOfValidation: string;
  submitted: boolean;
};

type AddingHistoryState = {
  status: 'AddingHistoryState';
  histories: HistoryFieldMeta[];
  history: HistoryFieldMeta;
  historyCopy?: HistoryFieldMeta;
  numberOfYearsRequiredMet: boolean;
  numberOfYearsMissing: string;
  yearsOfValidation: string;
  submitted: boolean;
};

type InvalidState = {
  status: 'InvalidState';
  histories: HistoryFieldMeta[];
  history?: HistoryFieldMeta;
  historyCopy?: HistoryFieldMeta;
  numberOfYearsRequiredMet: boolean;
  numberOfYearsMissing: string;
  yearsOfValidation: string;
  submitted: boolean;
};

type ValidState = {
  status: 'ValidState';
  histories: HistoryFieldMeta[];
  history?: HistoryFieldMeta;
  historyCopy?: HistoryFieldMeta;
  numberOfYearsRequiredMet: boolean;
  numberOfYearsMissing: string;
  yearsOfValidation: string;
  submitted: boolean;
};

type EditingHistoryState = {
  status: 'EditingHistoryState';
  histories: HistoryFieldMeta[];
  history: HistoryFieldMeta;
  historyCopy: HistoryFieldMeta;
  numberOfYearsRequiredMet: boolean;
  numberOfYearsMissing: string;
  yearsOfValidation: string;
  submitted: boolean;
};

type DeletingHistoryState = {
  status: 'DeletingHistoryState';
  histories: HistoryFieldMeta[];
  history: HistoryFieldMeta;
  historyCopy: HistoryFieldMeta;
  numberOfYearsRequiredMet: boolean;
  numberOfYearsMissing: string;
  yearsOfValidation: string;
  submitted: boolean;
};

type SubmittedState = {
  status: 'SubmittedState';
  histories: HistoryFieldMeta[];
  history: HistoryFieldMeta;
  historyCopy: HistoryFieldMeta;
  numberOfYearsRequiredMet: boolean;
  numberOfYearsMissing: string;
  yearsOfValidation: string;
  submitted: boolean;
};

export type State =
  | InitState
  | AddingHistoryState
  | InvalidState
  | ValidState
  | EditingHistoryState
  | DeletingHistoryState
  | SubmittedState;

type AddHistoryAction = {
  type: 'AddHistoryAction';
  histories: HistoryFieldMeta[];
  history: HistoryFieldMeta;
  historyCopy?: HistoryFieldMeta;
  numberOfYearsRequiredMet: boolean;
  numberOfYearsMissing: string;
  yearsOfValidation: string;
  submitted: boolean;
};

type DeleteHistoryAction = {
  type: 'DeleteHistoryAction';
  history: HistoryFieldMeta;
  histories: HistoryFieldMeta[];
  historyCopy: HistoryFieldMeta;
  numberOfYearsRequiredMet: boolean;
  numberOfYearsMissing: string;
  yearsOfValidation: string;
};

type EditHistoryAction = {
  type: 'EditHistoryAction';
  history: HistoryFieldMeta;
  histories: HistoryFieldMeta[];
  historyCopy: HistoryFieldMeta;
  numberOfYearsRequiredMet: boolean;
  numberOfYearsMissing: string;
  yearsOfValidation: string;
};

type InvalidHistoryAction = {
  type: 'InvalidHistoryAction';
  histories: HistoryFieldMeta[];
  submitted: boolean;
  numberOfYearsRequiredMet: boolean;
  numberOfYearsMissing: string;
  yearsOfValidation: string;
  childComponentValues: AllTheChildrenValues;
};

type Action =
  | AddHistoryAction
  | DeleteHistoryAction
  | EditHistoryAction
  | InvalidHistoryAction;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'AddHistoryAction':
      return {
        histories: action.histories,
        status: 'AddingHistoryState',
        submitted: action.submitted,
        history: action.history,
        historyCopy: action.historyCopy,
        numberOfYearsMissing: action.numberOfYearsMissing,
        numberOfYearsRequiredMet: action.numberOfYearsRequiredMet,
        yearsOfValidation: action.yearsOfValidation,
      };
    case 'InvalidHistoryAction':
      return {
        histories: action.histories,
        status: 'InvalidState',
        submitted: action.submitted,
        numberOfYearsMissing: action.numberOfYearsMissing,
        numberOfYearsRequiredMet: action.numberOfYearsRequiredMet,
        yearsOfValidation: action.yearsOfValidation,
      };
    case 'EditHistoryAction':
      return {
        status: 'EditingHistoryState',
        histories: action.histories,
        history: action.history,
        historyCopy: action.history,
        submitted: false,
        numberOfYearsRequiredMet: action.numberOfYearsRequiredMet,
        yearsOfValidation: action.yearsOfValidation,
        numberOfYearsMissing: action.numberOfYearsMissing,
      };
    case 'DeleteHistoryAction':
      return {
        ...state,
        status: 'DeletingHistoryState',
        history: action.history,
        histories: action.histories,
        historyCopy: action.history,
        numberOfYearsRequiredMet: action.numberOfYearsRequiredMet,
        yearsOfValidation: action.yearsOfValidation,
        numberOfYearsMissing: action.numberOfYearsMissing,
      };
    default:
      return { ...state };
  }
};

export const initialState: State = {
  status: 'InitState',
  histories: [],
  history: {
    startMonth: 0,
    startYear: 0,
    endMonth: 0,
    endYear: 0,
    active: false,
    covered: false,
    validDates: false,
    noHistory: false,
    childComponentValues: {},
  },
  submitted: false,
  numberOfYearsRequiredMet: false,
  numberOfYearsMissing: '15',
  yearsOfValidation: '15',
};
