import React from 'react';

import { RichText, RichTextProps } from './RichText';
import { useResizeEmbeds } from './useResizedEmbeds';

export const RichTextWithEmbeds: React.FC<RichTextProps> = ({
  text,
  ...props
}) => {
  const { ref } = useResizeEmbeds({ text });
  return <RichText ref={ref} text={text} {...props} />;
};
