import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    addressRoot: {
      width: '100%',
      '& label': {
        marginBottom: theme.spacing(2),
      },
      '& p': {
        marginBottom: theme.spacing(1),
      },
      '& .MuiOutlinedInput-root': {
        marginBottom: 0,
      },
    },
    button: {
      padding: 0,
    },
    clearButton: {
      marginTop: theme.spacing(3),
      padding: 0,
    },
    activeCheckboxContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    activeCheckbox: {
      '& span': {
        paddingLeft: 0,
      },
    },
    requiredLabel: {
      display: 'flex',
      gap: '8px',
      marginBlockEnd: '8px',
    },
    requiredIcon: {
      transform: 'translateY(8px)',
      fontSize: '0.5rem',
    },
    select: {
      '& .MuiSelect-root': {
        padding: theme.spacing(2),
      },
    },
  }),
);
