import { Box, Button, Typography } from '@material-ui/core';
import { ReloadableResultStatus } from 'api/resultStatus';
import {
  ApplicantPortalService,
  DataFields,
  FileUpload,
} from 'api-clients/monolith';
import React, { FormEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { LoadingBoundary } from 'components/LoadingBoundary';
import { Snackbar } from 'components/Snackbar';
import {
  ProgressManager,
  SubmitProps,
} from 'features/DataCollection/ProgressManager';

import { FileUploadRequest } from './index';

export type FileUploadRecollectionFormProps = {
  accountSlug: string;
  applicantExternalId: string;
  uploadRequests: FileUploadRequest[];
  uploadConfig: FileUpload;
};

export const FileUploadRecollectionForm: React.VFC<
  FileUploadRecollectionFormProps
> = ({ accountSlug, applicantExternalId, uploadRequests, uploadConfig }) => {
  const [errorOpen, setErrorOpen] = useState(false);

  type SubmitStatus = ReloadableResultStatus<null> | { status: 'idle' };

  const [submitting, setSubmitting] = useState<SubmitStatus>({
    status: 'idle',
  });

  const getOriginalBlobId = (key: string) => {
    return uploadRequests.find(req => {
      return req.key === key;
    })?.blob_id;
  };

  const handleSubmit = async (
    formData: FormData,
    event: FormEvent<Element>,
  ) => {
    event.preventDefault();
    type UploadedFile = Parameters<
      typeof ApplicantPortalService.patchInternalApiPortalApplicationsFileUploadRequests
    >[2]['uploaded_files'][number];

    setSubmitting({
      isLoading: true,
      isError: false,
      status: 'loading',
    });
    try {
      const uploadedFiles: UploadedFile[] = [];

      formData.forEach((value, key) => {
        const file = JSON.parse(value.toString()) as UploadedFile;
        const blobId = getOriginalBlobId(key);
        file.existing_blob_id = blobId ?? 0;
        uploadedFiles.push(file);
      });

      await ApplicantPortalService.patchInternalApiPortalApplicationsFileUploadRequests(
        accountSlug,
        applicantExternalId,
        { uploaded_files: uploadedFiles },
      );
      setSubmitting({
        isLoading: false,
        isError: false,
        status: 'ready',
        data: null,
      });
    } catch {
      setErrorOpen(true);
      setSubmitting({
        isLoading: false,
        isError: true,
        status: 'error',
      });
    }
  };

  const dataFields = (requests: FileUploadRequest[]) => {
    return requests.map(request => {
      const fileUploadField: DataFields[number] = {
        id: request.id,
        type: 'file',
        key: request.key,
        question: request.question,
        hint: request.hint ?? '',
        predefined: false,
        required: true,
        extra: {},
      };
      return fileUploadField;
    });
  };

  const Submit: React.VFC<SubmitProps> = ({ onClick }) => {
    return (
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        <FormattedMessage
          defaultMessage="Submit"
          description="Submit Button for File Upload"
        />
      </Button>
    );
  };

  /* Render form is it hasn't been submitted or an error was encountered during submision */
  const shouldRenderForm = () => {
    return submitting.status === 'idle' || submitting.status === 'error';
  };

  return (
    <LoadingBoundary loading={submitting.status === 'loading'}>
      {submitting.status === 'ready' && (
        <Typography variant="h2">
          <FormattedMessage
            defaultMessage="Upload completed. Thanks! The team will be in touch soon."
            description="File recollection upload complete"
          />
        </Typography>
      )}
      {shouldRenderForm() && (
        <>
          <Box mb={4}>
            <Typography variant="body2">
              <FormattedMessage
                defaultMessage="There was an issue with your previous upload, make sure the file or photo is clear and has the correct information."
                description="Recollect File Upload Message"
              />
            </Typography>
          </Box>
          <ProgressManager
            dataFields={dataFields(uploadRequests)}
            uploadFields={uploadConfig}
            onSubmit={handleSubmit}
            Submit={Submit}
            inputName={key => key}
          />
          <Snackbar
            severity="error"
            message={
              <FormattedMessage
                defaultMessage="Error submitting upload."
                description="Error message for submitting uploaded files"
              />
            }
            onClose={() => {
              setErrorOpen(false);
            }}
            open={errorOpen}
          />
        </>
      )}
    </LoadingBoundary>
  );
};
