import { FormControl, FormGroup, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataFields } from 'api-clients/monolith';
import cx from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '../Typography';
import { withMuiError } from '../WithMuiError';
import { CheckboxControl } from './CheckboxControl';

export const useStyles = makeStyles(theme => ({
  checkboxes: {
    width: '100%',
  },
  formRoot: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    marginBottom: '1.75rem',
  },
  withError: {
    borderRadius: 5,
    boxShadow: `0 0 1px 1px ${theme.palette.common.red400}`,
    marginBottom: 0,
  },
}));

type CheckboxesProps = {
  id: string;
  options: DataFields[number]['options'];
  baseName: string;
  required: boolean;
  hint?: string;
  error?: boolean;
  onInvalid?: React.FormEventHandler<HTMLButtonElement>;
  onChange?: React.ChangeEventHandler<HTMLElement>;
};
const isAnyChecked = (nodes: NodeListOf<HTMLInputElement>): boolean => {
  let anyChecked = false;
  nodes.forEach(function anyBox(checkbox) {
    anyChecked = anyChecked || checkbox.checked;
  });
  return anyChecked;
};

const setAllInvalid = (nodes: NodeListOf<HTMLInputElement>) => {
  nodes.forEach(checkbox =>
    checkbox.setCustomValidity('You must select at least one option'),
  );
};

const setAllValid = (nodes: NodeListOf<HTMLInputElement>) => {
  nodes.forEach(checkbox => checkbox.setCustomValidity(''));
};

const Checkboxes: React.VFC<CheckboxesProps> = ({
  id,
  options,
  baseName,
  required,
  error,
  onInvalid,
  onChange,
}) => {
  // Store a ref to a container element to make it easy to select the checkboxes
  const parentEl = useRef<HTMLFieldSetElement>(null);
  const styles = useStyles();

  // Use that ref to grab all the checkboxes.
  const checkboxes = () =>
    (parentEl.current?.querySelectorAll('input[type="checkbox"]') ??
      []) as NodeListOf<HTMLInputElement>;

  // When any checkbox changes, see if any are checked and set the validity
  const handleChange = useCallback(
    (event?: React.FormEvent<HTMLElement>) => {
      const boxes = checkboxes();
      if (isAnyChecked(boxes)) {
        setAllValid(boxes);
      } else {
        setAllInvalid(boxes);
      }

      if (event && onChange) {
        onChange(event as React.ChangeEvent<HTMLElement>);
      }
    },
    [onChange],
  );

  // Mark the inputs as invalid when the form initially renders.
  // Without this you would have to check and uncheck a box for the validation
  // to take effect.
  useEffect(() => {
    if (required) {
      handleChange();
    } else {
      setAllValid(checkboxes());
    }
  }, [required, handleChange]);

  return (
    <FormControl
      id={id}
      component="fieldset"
      ref={parentEl}
      onChange={required ? handleChange : undefined}
      className={styles.checkboxes}
      error={error}
    >
      <FormGroup
        classes={{
          root: cx(styles.formRoot, { [styles.withError]: error }),
        }}
      >
        {(options ?? []).map(option => (
          <CheckboxControl
            // The `[]` indicates that the form input
            // is an array of items to the backend controller
            name={`${baseName}[]`}
            label={option.label}
            key={option.value}
            value={option.value}
            defaultOption={option.default}
            onInvalid={onInvalid}
          />
        ))}
      </FormGroup>
      <FormHelperText component={Typography} error={error}>
        {error && (
          <FormattedMessage
            defaultMessage="Please enter a value"
            description="Error message body when a user submits the question with an empty value"
          />
        )}
      </FormHelperText>
    </FormControl>
  );
};

const ValidatedCheckboxes = withMuiError(Checkboxes);
ValidatedCheckboxes.displayName = 'Checkboxes';

export { ValidatedCheckboxes as Checkboxes };

type ValidatedCheckboxesProps = React.ComponentProps<
  typeof ValidatedCheckboxes
>;

export type { ValidatedCheckboxesProps as CheckboxesProps };
