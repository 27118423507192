/* eslint-disable jsx-a11y/label-has-associated-control */
/* Disabled associated control rule because it doesn't realize TextField is an input */
import {
  Button,
  Divider,
  FormControl,
  Select,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormHelperText } from 'components/FormHelperText';
import { RequiredIcon } from 'components/RequiredIcon';

import { useCountries, useCountryStates } from '../../hooks/useCountries';
import { Snackbar } from '../Snackbar';
import { BaseTextField, TextField } from '../TextField';
import { useStyles } from './styles';
import { SuggestionMenu } from './SuggestionMenu';
import { AddressValues, useSuggestedAddress } from './useSuggestedAddress';

export type InitialAddressFields = {
  initialStreetAddress: string;
  initialCity: string;
  initialStateCode: string;
  initialPostalCode: string;
  initialCountryCode?: string;
  initialAddress2?: string;
  initialStateCodeName?: string;
  initialStateCodeIso?: string;
  initialCountryCodeIso?: string;
};

export interface AddressFieldProps {
  inputName(key: string): string;
  required: boolean;
  id: string;
  shouldValidate?: boolean;
  countryHidden?: boolean;
  initialAddress?: InitialAddressFields;
  ANSIStateCode?: boolean;
  locationCountryCode?: string;
}

const MIN_PHRASE = 3;

const ANSI_STATE_CODES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

const hasInput = (values: AddressValues): boolean => {
  const {
    country_code: _countryCode, // eslint-disable-line @typescript-eslint/no-unused-vars
    country_code_iso: _countryCodeIso, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...valuesToCheck
  } = values;
  return Object.values(valuesToCheck).some(
    val => val && (val as string).trim().length > 0,
  );
};

/*
 * Monolith expects the address in the form of [street, address2, {hash of additional fields}]
 * so there is some twiddling of field names in this file to satisfy that. We should undo this
 * when 1.0 goes away and simplify to the regular object syntax.
 */
export const AddressField: React.VFC<AddressFieldProps> = ({
  inputName,
  required,
  id,
  shouldValidate,
  countryHidden,
  initialAddress,
  ANSIStateCode,
  locationCountryCode,
}) => {
  const emptyAddressFields: AddressValues = {
    street_address: '',
    address_2: '',
    city: '',
    state_code: '',
    state_code_iso: '',
    state_code_name: '',
    postal_code: '',
    country_code: '',
    country_code_iso: '',
  };

  const styles = useStyles();
  const intl = useIntl();
  const { result } = useCountries();
  const [addressFields, setAddressFields] = useState(emptyAddressFields);
  const [suggestionsOpen, setSuggestionsOpen] = useState(true);
  const [detailAlertOpen, setDetailAlertOpen] = useState(false);
  const [showRequiredLabel, setShowRequiredLabel] = useState(false);
  const [ansiError, setAnsiError] = useState(required);
  const { suggestions, getPlace, setEnabled } = useSuggestedAddress(
    addressFields.street_address,
    MIN_PHRASE,
  );
  const { result: countryStateResult } = useCountryStates(
    addressFields.country_code_iso,
  );

  useEffect(() => {
    const fieldHasValue = hasInput(addressFields);

    if (initialAddress && !fieldHasValue) {
      const newAddressFields = {
        street_address: initialAddress?.initialStreetAddress ?? '',
        address_2: initialAddress?.initialAddress2 ?? '',
        city: initialAddress?.initialCity ?? '',
        state_code: initialAddress?.initialStateCode ?? '',
        state_code_iso: initialAddress?.initialStateCodeIso ?? '',
        state_code_name: initialAddress?.initialStateCodeName ?? '',
        postal_code: initialAddress?.initialPostalCode ?? '',
        country_code: initialAddress?.initialCountryCode ?? '',
        country_code_iso: initialAddress?.initialCountryCodeIso ?? '',
      };
      setAddressFields(newAddressFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAddress]);

  useEffect(() => {
    const fieldHasValue = hasInput(addressFields);

    if (fieldHasValue || required) {
      setShowRequiredLabel(true);
    } else {
      setShowRequiredLabel(false);
    }
  }, [addressFields, required]);

  useEffect(() => {
    if (locationCountryCode && result.status === 'ready') {
      const country = result.data.find(
        country => country[1] === locationCountryCode,
      );
      const countryCode = country ? country[0] : null;

      if (countryCode)
        setAddressFields(oldAddress => ({
          ...oldAddress,
          country_code_iso: locationCountryCode,
          country_code: countryCode,
        }));
    }
  }, [locationCountryCode, result]);

  const stripPrefix = useCallback(
    (inputId: string) => {
      return inputId.replace('autocomplete_', '').replace(`${id}-`, '');
    },
    [id],
  );

  const stateCodeLabel = intl.formatMessage({
    defaultMessage: 'State / Province',
    description: 'State / Province text field title',
  });

  const handleInputs = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, id: inputId } = event.target;
      const key = inputId === id ? 'street_address' : stripPrefix(inputId);
      if (!ANSIStateCode && (key === 'country_code' || key === 'state_code')) {
        return false;
      }
      if (inputId === id && value.length >= MIN_PHRASE) {
        setSuggestionsOpen(true);
        setEnabled(true);
      }
      setAddressFields(oldAddress => ({
        ...oldAddress,
        [key]: value,
      }));
      return true;
    },
    [setEnabled, id, stripPrefix, ANSIStateCode],
  );

  const handleSelectPlace = useCallback(
    async (placeId: string) => {
      try {
        const details = await getPlace(placeId);
        if (ANSIStateCode) {
          // When this component is used in the I9Stage BasicEmployeeInformationForm
          // the full state name will fail validation so the state_code_iso is used
          setAddressFields({ ...details, state_code: details.state_code_iso });
          return;
        }
        // Making this modification here so that we don't affect other consumers of the getPlace() function
        // For the new dropdowns (this used to be free form text field) we need to have a long name for the display name
        // and a short name for the value.  Historically, state_code was the iso compliant so we have old users of this that will expect that.
        // So I've added state_code_name as additive key to the response and will spread it in here to the state_code key for the addressField
        setAddressFields({ ...details, state_code: details.state_code_name });
      } catch (error) {
        setDetailAlertOpen(true);
      }
    },
    [getPlace, ANSIStateCode],
  );

  const nestedName = useCallback(
    (field?: keyof AddressValues): string =>
      field ? `${inputName(id)}[${field}]` : `${inputName(id)}`,
    [id, inputName],
  );

  const suggestionsVisible = suggestions.status !== 'idle' && suggestionsOpen;

  const handleAlertClose = () => {
    setDetailAlertOpen(false);
  };

  const emptyField = () => {
    setAddressFields(emptyAddressFields);
  };

  const forceRequire = required || hasInput(addressFields);
  const showError = (value: string) =>
    forceRequire && shouldValidate && value.length === 0;

  const handleStateCodeValidity = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const currentInput = event.target.value;
    if (
      (!forceRequire && currentInput === '') ||
      ANSI_STATE_CODES.includes(currentInput.toUpperCase())
    ) {
      event.target.setCustomValidity('');
      setAnsiError(false);
    } else {
      event.target.setCustomValidity(
        'Enter a valid state code (e.g. CA, MN, NY, etc)',
      );
      setAnsiError(true);
    }
  };

  const handleCountryChange = useCallback(
    (event: React.ChangeEvent<{ name?: string }>) => {
      const selectElement = event.target as HTMLSelectElement;
      const selectedOption = selectElement.options[selectElement.selectedIndex];
      const countryName = selectedOption.text;
      const countryCode = selectElement.value;

      if (event) {
        setAddressFields(oldAddress => ({
          ...oldAddress,
          country_code: countryName,
          country_code_iso: countryCode,
        }));
      }
    },
    [],
  );

  const handleStateChange = useCallback(
    (event: React.ChangeEvent<{ name?: string }>) => {
      const selectElement = event.target as HTMLSelectElement;
      const selectedOption = selectElement.options[selectElement.selectedIndex];
      const stateName = selectedOption.text;
      const stateCode = selectElement.value;

      if (event) {
        setAddressFields(oldAddress => ({
          ...oldAddress,
          state_code: stateCode,
          state_code_iso: stateCode,
          state_code_name: stateName,
        }));
      }
    },
    [],
  );

  const emptyStateList = Boolean(
    countryStateResult.status === 'ready' && countryStateResult.data.length < 1,
  );

  const countryError = Boolean(
    required && shouldValidate && !addressFields.country_code,
  );

  const stateError = Boolean(
    forceRequire &&
      shouldValidate &&
      addressFields.country_code &&
      !addressFields.state_code_iso &&
      !emptyStateList,
  );

  const RequiredLabel: React.VFC<{ text: string }> = ({ text }) => {
    return (
      <div className={styles.requiredLabel}>
        <Typography component="span">{text}</Typography>
        {showRequiredLabel && (
          <RequiredIcon
            data-testid="required-icon"
            className={styles.requiredIcon}
            color="error"
          />
        )}
      </div>
    );
  };

  return (
    <div onChange={handleInputs} data-addressroot>
      <FormControl
        component="fieldset"
        className={styles.addressRoot}
        variant="outlined"
      >
        <FormControl variant="outlined">
          <label hidden={countryHidden}>
            <RequiredLabel
              text={intl.formatMessage({
                defaultMessage: 'Country',
                description: 'Country text field',
              })}
            />
            <Select
              fullWidth
              name={nestedName('country_code')}
              id={`${id}-country_code`}
              className={styles.select}
              value={addressFields.country_code_iso}
              native
              error={countryError}
              required={!countryHidden && forceRequire}
              onChange={handleCountryChange}
            >
              <option value="" selected></option>
              {result.status !== 'ready' ? (
                <option value="">
                  {intl.formatMessage({
                    defaultMessage: 'Loading…',
                    description: 'Dropdown loading message',
                  })}
                </option>
              ) : (
                result.data.map(country => (
                  <option key={country[1]} value={country[1]}>
                    {country[0]}
                  </option>
                ))
              )}
            </Select>
            <FormHelperText error={countryError}>
              {countryError &&
                intl.formatMessage({
                  defaultMessage: 'Please select an option',
                  description:
                    'Message shown if a user does not select anything from the dropdown.',
                })}
            </FormHelperText>
          </label>
        </FormControl>
        <label htmlFor={id}>
          <RequiredLabel
            text={intl.formatMessage({
              defaultMessage: 'Address',
              description: 'Address text field title',
            })}
          />
          <BaseTextField
            id={id}
            placeholder={intl.formatMessage({
              defaultMessage: 'Start typing your address...',
              description: 'Placeholder text for first Address title',
            })}
            required={forceRequire}
            error={showError(addressFields.street_address)}
            helperText={intl.formatMessage({
              defaultMessage: 'Address is required',
              description:
                'Indicates the Address is required for the address input',
            })}
            autoComplete="address-line1"
            value={addressFields.street_address}
            role="combobox"
            aria-autocomplete="list"
            aria-expanded={suggestionsVisible}
            aria-controls={suggestionsVisible ? 'address-listbox' : undefined}
          />
          <SuggestionMenu
            suggestions={suggestions}
            onSelect={handleSelectPlace}
            onClickAway={() => setSuggestionsOpen(false)}
            open={suggestionsOpen}
          />
          <Snackbar
            severity="error"
            message={
              <FormattedMessage
                defaultMessage="Unable to load address details"
                description="Indicates that there is an issue loading address details"
              />
            }
            onClose={handleAlertClose}
            open={detailAlertOpen}
          />
        </label>
        <label>
          <Typography>
            <FormattedMessage
              defaultMessage="Address 2"
              description="Address 2 text field title"
            />
          </Typography>
          <BaseTextField
            id={`${id}-address_2`}
            name={nestedName('address_2')}
            autoComplete="address-line2"
            value={addressFields.address_2}
          />
        </label>
        <label>
          <RequiredLabel
            text={intl.formatMessage({
              defaultMessage: 'City / Town',
              description: 'City / Town text field title',
            })}
          />
          <BaseTextField
            id={`${id}-city`}
            name={nestedName('city')}
            autoComplete="address-level2"
            value={addressFields.city}
            required={forceRequire}
            error={showError(addressFields.city)}
            helperText={intl.formatMessage({
              defaultMessage: 'City / Town is required',
              description:
                'Indicates the City / Town is required for the address input',
            })}
          />
        </label>
        <FormControl variant="outlined">
          <label>
            {ANSIStateCode ? (
              <>
                <RequiredLabel text={stateCodeLabel} />
                <TextField
                  id={`${id}-state_code`}
                  name={nestedName('state_code')}
                  autoComplete="address-level1"
                  value={addressFields.state_code}
                  required={forceRequire}
                  error={ansiError && shouldValidate}
                  onChange={handleStateCodeValidity}
                  valueMissingText={intl.formatMessage({
                    defaultMessage:
                      'Valid state code (e.g. CA, MN, NY, etc) is required',
                    description:
                      'Message shown if state code is not in the list of accepted values.',
                  })}
                />
              </>
            ) : (
              <>
                {addressFields.country_code && !emptyStateList ? (
                  <RequiredLabel text={stateCodeLabel} />
                ) : (
                  <Typography>{stateCodeLabel}</Typography>
                )}
                <Select
                  fullWidth
                  id={`${id}-state_code`}
                  name={nestedName('state_code')}
                  className={styles.select}
                  disabled={result.status !== 'ready'}
                  value={addressFields.state_code_iso}
                  native
                  required={!emptyStateList && forceRequire}
                  error={stateError}
                  onChange={handleStateChange}
                >
                  <option value="" selected></option>
                  {countryStateResult.status !== 'ready' ? (
                    <option value="">
                      {intl.formatMessage({
                        defaultMessage: 'Loading…',
                        description: 'Dropdown loading message',
                      })}
                    </option>
                  ) : (
                    countryStateResult.data.map(state => (
                      <option key={state[1]} value={state[1]}>
                        {state[0]}
                      </option>
                    ))
                  )}
                </Select>
                <FormHelperText error={stateError}>
                  {stateError &&
                    intl.formatMessage({
                      defaultMessage: 'Please select an option',
                      description:
                        'Message shown if a user does not select anything from the dropdown.',
                    })}
                </FormHelperText>
              </>
            )}
          </label>
        </FormControl>
        <label>
          <RequiredLabel
            text={intl.formatMessage({
              defaultMessage: 'Zip / Postal Code',
              description: 'Zip / Postal Code text field title',
            })}
          />
          <BaseTextField
            id={`${id}-postal_code`}
            name={nestedName('postal_code')}
            autoComplete="postal-code"
            value={addressFields.postal_code}
            required={forceRequire}
            error={showError(addressFields.postal_code)}
            helperText={intl.formatMessage({
              defaultMessage: 'Zip / Postal code is required',
              description:
                'Indicates the Zip / Postal is required for the address input',
            })}
          />
        </label>
        <input
          type="hidden"
          name={nestedName('street_address')}
          value={addressFields.street_address}
        />
        <input
          type="hidden"
          name={nestedName('address_2')}
          value={addressFields.address_2}
        />
        <input
          type="hidden"
          name={nestedName('city')}
          value={addressFields.city}
        />
        <input
          type="hidden"
          name={nestedName('country_code_iso')}
          value={addressFields.country_code_iso}
        />
        <input
          type="hidden"
          name={nestedName('state_code_iso')}
          value={addressFields.state_code_iso}
        />
        <input
          type="hidden"
          name={nestedName('country_code')}
          value={addressFields.country_code}
        />
        <input
          type="hidden"
          name={nestedName('state_code')}
          value={addressFields.state_code}
        />
        <input
          type="hidden"
          name={nestedName('state_code_name')}
          value={addressFields.state_code_name}
        />
        <input
          type="hidden"
          name={nestedName('postal_code')}
          value={addressFields.postal_code}
        />
        {typeof addressFields.longitude !== 'undefined' && (
          <>
            <input
              type="hidden"
              name={nestedName('latitude')}
              value={addressFields.latitude}
            />
            <input
              type="hidden"
              name={nestedName('longitude')}
              value={addressFields.longitude}
            />
          </>
        )}
        <Divider />
      </FormControl>
      <Button
        onClick={emptyField}
        color="primary"
        className={styles.clearButton}
      >
        <FormattedMessage
          defaultMessage="Clear selection"
          description="Button for users to clear address fields & go back to previous page"
        />
      </Button>
    </div>
  );
};
