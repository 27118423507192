import { datadogLogs } from '@datadog/browser-logs';
import { Box, Button, FormControl, Typography } from '@material-ui/core';
import { FileUpload as FileUploadFields } from 'api-clients/monolith';
import { RouteParams } from 'contexts/appContext';
import React, { useCallback, useEffect, useRef, useState, VFC } from 'react';
import Dropzone, { formatBytes, IFileWithMeta } from 'react-dropzone-uploader';
import { classNames } from 'react-extras';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import UploadIcon from '../../images/upload-file.svg';
import { CircularProgress } from '../CircularProgress';
import { FilePreview } from './FilePreview';
import { dropzoneStyles, useStyles } from './styles';
import {
  getSecureDataUrl,
  getUploadParams,
  runBlurryDetection,
  setDoneInput,
} from './utils';

interface ErrorDetails {
  message: JSX.Element;
  type: string;
}

interface ErrorMessagesMap {
  [key: string]: ErrorDetails;
}

const ERROR_MESSAGES: ErrorMessagesMap = {
  error_required: {
    message: (
      <FormattedMessage
        defaultMessage="Please upload a file."
        description="Required upload error message."
      />
    ),
    type: 'required',
  },
  error_file_size: {
    message: (
      <FormattedMessage
        defaultMessage="The file is too large. Please try again."
        description="File size exceeded error message."
      />
    ),
    type: 'size',
  },
  error_upload: {
    message: (
      <FormattedMessage
        defaultMessage="The upload failed. Please try again."
        description="Failed upload error message."
      />
    ),
    type: 'upload',
  },
  exception_upload: {
    message: (
      <FormattedMessage
        defaultMessage="The upload failed. Please try again."
        description="Failed upload error message."
      />
    ),
    type: 'upload',
  },
  error_file_type: {
    message: (
      <FormattedMessage
        defaultMessage="Please upload a valid image or document file type, such as .jpg, .png, .pdf, or .docx"
        description="File type upload error message."
      />
    ),
    type: 'type',
  },
};

interface InputContentProps {
  maxSizeBytes: number;
  active: boolean;
}

const InputContent: VFC<InputContentProps> = ({ maxSizeBytes, active }) => {
  const styles = useStyles();
  const intl = useIntl();

  return (
    <FormControl
      className={classNames(styles.uploadInputContainer, {
        [styles.active]: active,
      })}
    >
      <Button
        component="span"
        variant="outlined"
        startIcon={
          <img
            alt={intl.formatMessage({
              defaultMessage: 'Upload',
              description: 'Upload Icon',
            })}
            className={styles.iconDark}
            src={UploadIcon}
          />
        }
      >
        <FormattedMessage
          defaultMessage="Upload File"
          description="File upload input button"
        />
      </Button>
      <Typography>
        <FormattedMessage
          defaultMessage="or drag and drop here"
          description="Indicates drag and drop option is available"
        />
      </Typography>
      <Typography>
        <FormattedMessage
          defaultMessage="Max file size:"
          description="Indicates file size restriction"
        />{' '}
        {formatBytes(maxSizeBytes)}
      </Typography>
    </FormControl>
  );
};

export interface FileUploadProps {
  id: string;
  required: boolean;
  name: string;
  uploadFields: FileUploadFields;
  shouldValidate?: boolean;
  dataFieldId: number;
}

export const FileUpload: VFC<FileUploadProps> = ({
  id,
  required,
  name,
  uploadFields,
  shouldValidate,
  dataFieldId,
}) => {
  const styles = useStyles();
  const dropzoneWrapper = useRef<HTMLDivElement>(null);
  const [selectedFile, setSelectedFile] = useState<IFileWithMeta | undefined>(
    undefined,
  );
  const [displayLoading, setDisplayLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ErrorDetails>();
  const [isBlurry, setIsBlurry] = useState(false);
  const { accountSlug, applicantExternalId } = useParams<RouteParams>();

  const getFileInput = (): HTMLInputElement | null => {
    if (!dropzoneWrapper.current) {
      return null;
    }
    return dropzoneWrapper.current.querySelector('input[type="file"]');
  };

  useEffect(() => {
    const input = getFileInput();
    const valInput = dropzoneWrapper.current?.querySelector(
      `input[name="${name}"]`,
    ) as HTMLInputElement;
    const inputSet = setDoneInput(
      selectedFile,
      valInput,
      uploadFields,
      dataFieldId,
    );

    if (!selectedFile && required) {
      input?.setAttribute('required', '');
    }
    if (shouldValidate && !selectedFile) {
      setErrorMessage(ERROR_MESSAGES.error_required);
    }
    if (selectedFile && inputSet) {
      input?.removeAttribute('required');
    }

    // Prevent form submission if the file is still being uploaded,
    // so that we can be sure that the hidden field is populated.
    input?.setCustomValidity(
      displayLoading || (required && !inputSet) ? 'uploading' : '',
    );
  }, [
    dataFieldId,
    displayLoading,
    name,
    required,
    selectedFile,
    shouldValidate,
    uploadFields,
  ]);

  useEffect(() => {
    const input = getFileInput();
    if (required) {
      input?.setAttribute('required', '');
    } else {
      input?.removeAttribute('required');
    }
  }, [required]);

  const checkErrorDisplay = (): boolean => {
    const fileTypeError = errorMessage?.type === 'type';
    const uploadFailed = errorMessage?.type === 'upload';
    const notSizeError = errorMessage?.type !== 'size';
    const notInErrorState = !errorMessage || displayLoading || !shouldValidate;

    if (fileTypeError || uploadFailed) {
      return true;
    }
    if (notSizeError && notInErrorState) {
      return false;
    }
    const requiredError = errorMessage.type === 'required';
    return !requiredError ? true : required;
  };

  const handleChange: (
    file: IFileWithMeta | undefined,
    status: string,
  ) => void = (file, status) => {
    let loading = false;

    switch (status) {
      case 'preparing':
        loading = true;
        selectedFile?.remove();
        setIsBlurry(false);
        setSelectedFile(undefined);
        setErrorMessage(undefined);
        break;
      case 'getting_upload_params':
      case 'uploading':
      case 'headers_received':
        loading = true;
        break;
      case 'done':
        if (file) {
          const invalidFileTypes = ['html', 'x-msdownload'];
          const isInvalidType = invalidFileTypes.some(
            type => file.meta.type.includes(type) || file.meta.type === '',
          );

          if (isInvalidType) {
            setSelectedFile(undefined);
            file?.remove();
            setErrorMessage(ERROR_MESSAGES.error_file_type);
            break;
          }

          setSelectedFile(file);

          if (file.meta.type.includes('image')) {
            if (uploadFields.lorgnette_endpoint) {
              const lorgnetteEndpoint =
                uploadFields.lorgnette_endpoint.includes('http')
                  ? uploadFields.lorgnette_endpoint
                  : `http://localhost:3000${uploadFields.lorgnette_endpoint}`;
              const secureDataUrl = getSecureDataUrl(
                file,
                uploadFields,
                accountSlug,
                applicantExternalId,
                dataFieldId,
              );

              runBlurryDetection(secureDataUrl, lorgnetteEndpoint)
                .then(blurryDetected => {
                  setIsBlurry(blurryDetected);
                })
                .catch((err: unknown) => {
                  datadogLogs.logger.error(
                    err instanceof Error
                      ? err.message
                      : 'Failed to return blurry state.',
                  );
                });
            } else {
              datadogLogs.logger.error(
                'Failed to run blurry state check. LORGNETTE_ENDPOINT environment variable not set',
              );
            }
          }

          setErrorMessage(undefined);
        }
        break;
      default:
        break;
    }

    if (
      status.split('_')[0] === 'error' ||
      status.split('_')[0] === 'exception'
    ) {
      setSelectedFile(undefined);
      file?.remove();
      setErrorMessage(ERROR_MESSAGES[status]);
    }

    setDisplayLoading(loading);
  };

  const removeSelected = useCallback(() => {
    selectedFile?.remove();
    setSelectedFile(undefined);
    setIsBlurry(false);

    if (required) {
      setErrorMessage(ERROR_MESSAGES.error_required);
    }
  }, [required, selectedFile]);

  const UploadingState = () => {
    return (
      <Box className={styles.loadingContainer}>
        <CircularProgress className={styles.circularProgress} size={25} />
        <Typography variant="body2">
          <FormattedMessage
            defaultMessage="Uploading"
            description="Pending file upload indicator"
          />
        </Typography>
      </Box>
    );
  };

  return (
    <div ref={dropzoneWrapper} className={styles.uploadWrapper}>
      <Dropzone
        styles={dropzoneStyles}
        onChangeStatus={handleChange}
        maxSizeBytes={uploadFields?.max_size}
        multiple={false}
        getUploadParams={getUploadParams(uploadFields, dataFieldId)}
        inputContent={(_inputProps, dropzoneProps) =>
          selectedFile === undefined && (
            <InputContent key="inputContent" {...dropzoneProps} />
          )
        }
        inputWithFilesContent={null}
        PreviewComponent={selectedFile ? FilePreview : undefined}
      />
      {checkErrorDisplay() && (
        <Typography className={styles.error}>
          {errorMessage?.message}
        </Typography>
      )}
      {isBlurry && !displayLoading && (
        <Typography className={styles.error}>
          <FormattedMessage
            defaultMessage="This image appears blurry. Retake the photo with a steady hand in a
            well lit space for best results."
            description="Blurry image detected warning"
          />
        </Typography>
      )}
      {displayLoading && <UploadingState />}
      {selectedFile && (
        <Button
          className={styles.deleteButton}
          variant="outlined"
          onClick={removeSelected}
        >
          <FormattedMessage
            defaultMessage="Delete file"
            description="Removes the file from the form when clicked."
          />
        </Button>
      )}
      <input id={id} type="hidden" name={name} />
    </div>
  );
};
