/* eslint-disable jsx-a11y/label-has-associated-control */
/* Disabled associated control rule because it doesn't realize TextField is an input */
import { Box, Button, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Checkbox } from 'components/Checkbox';
import { Datepicker } from 'components/Datepicker';
import { calculateMinDate } from 'utils/calculateMinDate';

import { AddressField } from '../AddressField/AddressField';
import { useStyles } from './styles';

export interface AddressHistoryFieldProps {
  inputName(key: string): string;
  required: boolean;
  id: string;
  shouldValidate?: boolean;
}

export interface AddresssHistoryValues {
  // eslint-disable-next-line camelcase
  begin_date: '';
  // eslint-disable-next-line camelcase
  end_date: '';
  active: false;
}

export const AddressHistoryField: React.VFC<AddressHistoryFieldProps> = ({
  inputName,
  required,
  id,
  shouldValidate,
}) => {
  // Multiple AddressFields
  const [addressHistoryFields, setAddressHistoryFields] = useState([
    { key: Math.random(), end_date: '', begin_date: '', active: false },
  ]);

  const styles = useStyles();

  const handleInputs = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const { value, id: inputId } = event.target;
      const key = inputId.replace(`${id}-`, '');

      const nextAddressHistoryfields = addressHistoryFields.map(
        (ahf, ahfIndex) => {
          if (ahfIndex === index) {
            return {
              ...ahf,
              [key]: value,
            };
          }
          return ahf;
        },
      );
      setAddressHistoryFields(nextAddressHistoryfields);
    },
    [addressHistoryFields, id],
  );

  const handleActiveCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = !!e.target.checked;
    const endDateValue = value ? '' : addressHistoryFields[index].end_date;
    const nextAddressHistoryfields = addressHistoryFields.map(
      (ahf, ahfIndex) => {
        if (ahfIndex === index) {
          return {
            ...ahf,
            active: value,
            end_date: endDateValue,
          };
        }
        return ahf;
      },
    );

    setAddressHistoryFields(nextAddressHistoryfields);
  };

  const nestedName = useCallback(
    (index: number, field: keyof AddresssHistoryValues): string => {
      return `${inputName(id)}[${index}][${field}]`;
    },
    [id, inputName],
  );

  const indexedName = (dataKey: string, index: number) => {
    return `data_collection[${dataKey}][${index}]`;
  };

  const handleAddField = () => {
    setAddressHistoryFields([
      ...addressHistoryFields,
      { key: Math.random(), begin_date: '', end_date: '', active: false },
    ]);
  };

  const handleRemoveField = () => {
    setAddressHistoryFields(addressHistoryFields.slice(0, -1));
  };

  const showError = (value: string) => shouldValidate && value.length === 0;

  return (
    <div data-addresshistoryroot>
      {addressHistoryFields.map((addressHistoryField, index) => (
        <Box
          mb={4}
          key={addressHistoryField.key}
          id={`address_history_${index + 1}`}
        >
          <Typography className={styles.addressHeader}>
            <FormattedMessage
              defaultMessage="Address History {addressIndex}"
              description="Header for Address History fields."
              values={{ addressIndex: index + 1 }}
            />
          </Typography>

          <AddressField
            inputName={(dataKey: string) => {
              return indexedName(dataKey, index + 1);
            }}
            id={id.toString()}
            required={required}
            shouldValidate={shouldValidate}
          />
          <label>
            <Typography>
              <FormattedMessage
                defaultMessage="Begin Date"
                description="Begin date field"
              />
            </Typography>
            <Datepicker
              id={`${id}-begin_date`}
              name={nestedName(index + 1, 'begin_date')}
              required
              // shouldValidate={shouldValidate && hasInput(addressFields)}
              error={showError(addressHistoryField.begin_date)}
              value={addressHistoryField.begin_date}
              inputProps={{ max: calculateMinDate() }}
              helperText="Beginning date of address"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputs(e, index);
              }}
            />
          </label>
          <label hidden={addressHistoryField.active}>
            <Typography>
              <FormattedMessage
                defaultMessage="End Date"
                description="End date field"
              />
            </Typography>
            <Datepicker
              id={`${id}-end_date`}
              name={nestedName(index + 1, 'end_date')}
              required={!addressHistoryField.active}
              // shouldValidate={shouldValidate && hasInput(addressFields)}
              error={
                !addressHistoryField.active &&
                showError(addressHistoryField.end_date)
              }
              value={addressHistoryField.end_date}
              inputProps={{
                min: addressHistoryField.begin_date,
                max: calculateMinDate(),
              }}
              helperText="Ending date of address"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputs(e, index);
              }}
            />
          </label>
          <label>
            <div className={styles.activeCheckboxContainer}>
              <div className={styles.activeCheckbox}>
                <Checkbox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleActiveCheckbox(e, index);
                  }}
                  id={`${id}-active`}
                  name={nestedName(index + 1, 'active')}
                  checked={!!addressHistoryField.active}
                  data-index-number={index + 1}
                  value
                />
              </div>
              <div>
                <Typography>
                  <FormattedMessage
                    defaultMessage="I am still here"
                    description="Active address checkbox"
                  />
                </Typography>
              </div>
            </div>
          </label>
        </Box>
      ))}
      <div className={styles.addRemoveAddressContainer}>
        <div>
          <Button variant="contained" color="primary" onClick={handleAddField}>
            Add Another Address
          </Button>
        </div>
        {addressHistoryFields.length > 1 && (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRemoveField}
            >
              Remove Last Address
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
