import { FormControlLabel as MuiFormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    // Left/right 8px margin option fields to fit within fieldset border
    // margin: `${spacing * 1}px ${spacing * 1.5}px !important`,
    // margin: `${theme.spacing(1, 1.5)} !important`,
    margin: theme.spacing(1),
    // padding: `${spacing * 1.5}px ${spacing * 2}px ${spacing * 1.5}px 0px`,
    padding: theme.spacing(1.5, 2, 1.5, 0),
    borderColor: theme.palette.common.gray300,
    border: '1px solid',
    borderRadius: 5,
    '&:hover': {
      borderColor: theme.palette.common.gray800,
      boxShadow: `0 1px 2px ${theme.palette.common.gray200}`,
    },
    '&:active': {
      backgroundColor: theme.palette.common.gray200,
      borderColor: theme.palette.common.gray400,
    },
    // N.B. There's probably a better way to set this from within components,
    // though just wrapping `label` in `<Typography variant="bodyCompact">`
    // causes a double wrap
    '& .MuiTypography-body1': {
      lineHeight: 1.25,
    },
  },
  PrivateSwitchBase: {
    root: {
      padding: '0px 10px 0px 14px',
    },
  },
}));

type MuiProps = React.ComponentProps<typeof MuiFormControlLabel>;

export type FormControlLabelProps = Omit<MuiProps, 'color'>;

export const FormControlLabel: React.VFC<FormControlLabelProps> = ({
  classes,
  ...rest
}) => {
  const overrideClasses = useStyles();
  return (
    <MuiFormControlLabel
      {...rest}
      color="default"
      classes={{ ...(classes ?? null), ...overrideClasses }}
    />
  );
};
