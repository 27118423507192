import React, { useEffect, useRef, useState } from 'react';

import { useJumpUtils } from './logicJumpUtils';
import { SuccessiveView } from './SuccessiveView';
import { ProgressManagerProps } from './types';

export const LogicProgressManager: React.VFC<ProgressManagerProps> = ({
  onSubmit,
  dataFields,
  serverInvalidFields,
  ...rest
}) => {
  const [currentInput, setCurrentInput] = useState(0);
  const invalidField = useRef<boolean>(false);
  const [errorOpened, setErrorOpened] = useState(false);
  const { getJumpToDestination, getJumpBackDestination, buildFormData } =
    useJumpUtils();

  useEffect(() => {
    if (serverInvalidFields) {
      const firstKey = Object.keys(serverInvalidFields.errors)[0];
      const idx = dataFields.findIndex(df => df.key === firstKey);
      if (idx !== -1) {
        setCurrentInput(idx);
      }
    }
  }, [serverInvalidFields, dataFields]);

  const showError = () => {
    if (invalidField.current) {
      setErrorOpened(true);
      return true;
    }
    setErrorOpened(false);
    return false;
  };

  const focusFirstInvalid = (form: HTMLFormElement) => {
    const el = form.querySelector<HTMLElement>(
      'form > div:not([hidden]) input:invalid,textarea:invalid',
    );
    el?.focus();
  };

  const setInvalidInput = (form: HTMLFormElement) => {
    form.checkValidity();
    // set invalid field by finding the visible form fields that have an invalid pseudoclass
    invalidField.current = !!form.querySelector(
      'form > div:not([hidden]) *:invalid',
    );
  };

  const handleSubmit = (event: React.FormEvent) => {
    const form = event.target as HTMLFormElement;
    setInvalidInput(form);
    if (!showError()) {
      onSubmit(buildFormData(form), event);
    } else {
      event.preventDefault();
      focusFirstInvalid(form);
    }
  };

  const handleCurrentChange = (event: React.ChangeEvent<HTMLFormElement>) => {
    const valid = event.target.checkValidity();
    invalidField.current = !valid;
  };

  const handleNextClick = (event: React.MouseEvent<HTMLElement>) => {
    const form = (event.target as HTMLElement).closest('form');
    if (!form) {
      throw new Error('Form was not found');
    }
    setInvalidInput(form);
    if (!showError()) {
      const next = getJumpToDestination(form, currentInput, dataFields);

      if (next.submit) {
        onSubmit(buildFormData(form), event);
        return;
      }

      setCurrentInput(next.jumpToIndex);
    } else {
      focusFirstInvalid(form);
    }
  };

  const handlePreviousClick = () => {
    const prev = getJumpBackDestination();
    setCurrentInput(prev);
    invalidField.current = false;
  };

  return (
    <SuccessiveView
      handlePreviousClick={handlePreviousClick}
      handleNextClick={handleNextClick}
      handleCurrentChange={handleCurrentChange}
      handleSubmit={handleSubmit}
      errorOpened={errorOpened}
      dataFields={dataFields}
      currentInput={currentInput}
      isLogicJump
      {...rest}
    />
  );
};
