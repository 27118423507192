import { Typography } from '@material-ui/core';
import { ApplicantPortalService } from 'api-clients/monolith';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { LoadingBoundary } from 'components/LoadingBoundary';

import { useApiService } from '../../hooks/useApiService';
import { FileUploadRecollectionForm } from './FileUploadRecollectionForm';
import { FileUploadRequestData } from './index';

export interface FileUploadRecollectionProps {
  accountSlug: string;
  applicantExternalId: string;
}

export const FileUploadRecollection: React.VFC<FileUploadRecollectionProps> = ({
  accountSlug,
  applicantExternalId,
}) => {
  const fileUploadRequestService = useCallback(
    () =>
      ApplicantPortalService.getInternalApiPortalApplicationsFileUploadRequestsNew(
        accountSlug,
        applicantExternalId,
      ),
    [accountSlug, applicantExternalId],
  );

  const { result } = useApiService<FileUploadRequestData>(
    fileUploadRequestService,
  );

  return (
    <LoadingBoundary loading={result.status === 'loading'}>
      {result.status === 'error' && <span>Ded</span>}
      {result.status === 'ready' &&
        result.data.file_upload_requests.length > 0 && (
          <FileUploadRecollectionForm
            accountSlug={accountSlug}
            applicantExternalId={applicantExternalId}
            uploadRequests={result.data.file_upload_requests}
            uploadConfig={result.data.upload_config}
          />
        )}
      {result.status === 'ready' &&
        result.data.file_upload_requests.length === 0 && (
          <Typography variant="h2">
            <FormattedMessage
              defaultMessage="Upload completed. Thanks! The team will be in touch soon."
              description="File recollection upload complete"
            />
          </Typography>
        )}
    </LoadingBoundary>
  );
};
