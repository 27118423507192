import { Checkbox as MuiCheckbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.gray800,
    padding: theme.spacing(0, 1.25, 0, 1.75),
    '& svg': { height: 20, width: 20 },
    '&:hover': { backgroundColor: 'transparent' },
    '&.Mui-focusVisible svg': { outline: '2px auto' },
  },
}));

type MuiProps = React.ComponentProps<typeof MuiCheckbox>;

export type CheckboxProps = Omit<MuiProps, 'color'>;

export const Checkbox: React.VFC<CheckboxProps> = ({ classes, ...rest }) => {
  const overrideClasses = useStyles();
  return (
    <MuiCheckbox
      {...rest}
      color="default"
      classes={{ ...(classes ?? null), ...overrideClasses }}
    />
  );
};
