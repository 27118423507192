import { Button, Grid, Typography } from '@material-ui/core';
import React, { VFC } from 'react';
import { formatBytes, IMeta, IPreviewProps } from 'react-dropzone-uploader';
import { useIntl } from 'react-intl';

import Document from '../../images/document.svg';
import { useStyles } from './styles';

export interface FilePreviewProps extends IPreviewProps {
  meta: IMeta;
}

export const FilePreview: VFC<FilePreviewProps> = ({ meta }) => {
  const styles = useStyles();
  const intl = useIntl();
  const imageUpload = meta.type.includes('image');

  const Details = () => {
    return (
      <>
        <Typography variant="h3">{meta.name}</Typography>
        <Typography variant="body2">{formatBytes(meta.size)}</Typography>
      </>
    );
  };

  return (
    <>
      {meta.status !== 'preparing' && (
        <Grid container direction="column" className={styles.previewWrapper}>
          {imageUpload ? (
            <>
              <div className={styles.imagePreview}>
                <img alt="Preview" src={meta.previewUrl} />
              </div>
              <Details />
            </>
          ) : (
            <Button
              fullWidth
              className={styles.previewButton}
              variant="outlined"
              startIcon={
                <img
                  alt={intl.formatMessage({
                    defaultMessage: 'Document',
                    description: 'Document Icon',
                  })}
                  className={styles.iconDark}
                  src={Document}
                />
              }
            >
              <Grid container direction="column">
                <Details />
              </Grid>
            </Button>
          )}
        </Grid>
      )}
    </>
  );
};
