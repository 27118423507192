import { Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';

import { Loading } from '../Loading';

export const useStyles = makeStyles(theme => ({
  loading: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export type LoadingBoundaryProps = {
  className?: string;
  loading: boolean;
  invisible?: boolean;
  children?: React.ReactNode;
};

export const LoadingBoundary: FC<LoadingBoundaryProps> = ({
  className,
  loading,
  invisible,
  children,
}) => {
  const styles = useStyles();

  return (
    <div aria-live="polite" aria-busy={loading} className={className}>
      {loading && (
        <Backdrop open invisible={invisible} className={styles.loading}>
          <Loading />
        </Backdrop>
      )}
      {children}
    </div>
  );
};
