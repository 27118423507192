import { SvgIcon } from '@material-ui/core';
import React from 'react';

import type { SvgIconProps } from '@material-ui/core/SvgIcon';

export const ChevronRight: React.VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      clipRule="evenodd"
      d="M6.29289 4.29289C6.68342 3.90237 7.31658 3.90237 7.70711 4.29289L10.7071 7.29289C11.0976 7.68342 11.0976 8.31658 10.7071 8.70711L7.70711 11.7071C7.31658 12.0976 6.68342 12.0976 6.29289 11.7071C5.90237 11.3166 5.90237 10.6834 6.29289 10.2929L8.58579 8L6.29289 5.70711C5.90237 5.31658 5.90237 4.68342 6.29289 4.29289Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
