import { SvgIcon } from '@material-ui/core';
import React from 'react';

import type { SvgIconProps } from '@material-ui/core/SvgIcon';

export const ChevronLeft: React.VFC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      clipRule="evenodd"
      d="M9.70711 11.7071C9.31658 12.0976 8.68342 12.0976 8.29289 11.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289L8.29289 4.29289C8.68342 3.90237 9.31658 3.90237 9.70711 4.29289C10.0976 4.68342 10.0976 5.31658 9.70711 5.70711L7.41421 8L9.70711 10.2929C10.0976 10.6834 10.0976 11.3166 9.70711 11.7071Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
