import { ApiError, CancelablePromise, CancelError } from 'api-clients/monolith';
import { useCallback, useEffect, useState } from 'react';

import { ReloadableResultStatus } from '../api/resultStatus';

export interface UseApiService<T, E> {
  result: ReloadableResultStatus<T, E>;
  refetch: () => void;
}

/**
 * Should be kept in sync with the copy of `recruiter-ui`'s `useApiService`
 * hook to allow both to reference a single `:packages/react-hooks` or similar
 *
 * @see {@link :packages/recruiter-ui/app/hooks/useApiService.ts}
 */
export const useApiService = <T, E = unknown>(
  // This service should be memoized before passed in, for example by using `useCallback`.
  service: () => CancelablePromise<T>,
): UseApiService<T, E> => {
  const [result, setResult] = useState<ReloadableResultStatus<T, E>>({
    status: 'loading',
    isLoading: true,
    isError: false,
  });
  const [queryKey, setQueryKey] = useState(false);

  const setLoadingOrReloading = useCallback(() => {
    setResult(currentResult => {
      // Has loaded once succesfully and has valid cached data
      if (currentResult.status === 'ready') {
        return {
          status: 'reloading',
          data: currentResult.data,
          isLoading: true,
          isError: false,
        };
      }
      if (currentResult.status !== 'loading') {
        return {
          status: 'loading',
          isLoading: true,
          isError: false,
        };
      }
      return currentResult;
    });
  }, []);

  const refetch = useCallback(() => {
    setQueryKey(currentKey => !currentKey);
  }, []);

  useEffect(() => {
    setLoadingOrReloading();
    const promise = service();
    promise
      .then(data => {
        setResult({ status: 'ready', data, isLoading: false, isError: false });
      })
      .catch((e: Error) => {
        if (e instanceof CancelError) {
          return;
        }
        if (e instanceof ApiError) {
          setResult({
            status: 'error',
            isLoading: false,
            isError: true,
            error: (e.body as E) || (e.statusText as unknown as E),
          });
          return;
        }
        setResult({ status: 'error', isLoading: false, isError: true });
      });
    return () => promise.cancel();
  }, [service, queryKey, setLoadingOrReloading]);

  return { result, refetch };
};
