import { SvgIcon } from '@material-ui/core';
import React from 'react';

import type { SvgIconProps } from '@material-ui/core/SvgIcon';

export const Warning: React.VFC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <g id="Status-Icon-Warning">
      <path
        d="M22.8 20.25L13.35 2.55001C12.75 1.50001 11.25 1.50001 10.65 2.55001L1.2 20.25C0.600002 21.3 1.35 22.5 2.55 22.5H21.6C22.65 22.5 23.4 21.3 22.8 20.25ZM12 19.5C11.25 19.5 10.5 18.75 10.5 18C10.5 17.25 11.25 16.5 12 16.5C12.75 16.5 13.5 17.25 13.5 18C13.5 18.75 12.75 19.5 12 19.5ZM13.5 13.5C13.5 14.25 12.75 15 12 15C11.25 15 10.5 14.25 10.5 13.5V9.45001C10.5 8.70001 11.25 7.95001 12 7.95001C12.75 7.95001 13.5 8.70001 13.5 9.45001V13.5Z"
        id="Vector"
      />
    </g>
  </SvgIcon>
);
