import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/CheckBox';
import React from 'react';

import { Checkbox } from '../Checkbox';
import { FormControlLabel } from '../FormControlLabel';

export const useStyles = makeStyles(theme => ({
  checkboxLabel: {
    backgroundColor: theme.palette.common.white,
    '&:active $checkIcon': {
      display: 'inline-block',
    },
    // Still needs hidden when the Checkbox is checked though
    '& .Mui-checked + $checkIcon': {
      display: 'none',
    },
  },
  // N.B. the positioning styling is necessary to achieve correct positioning when
  // `CheckboxControl` is used within `Checkboxes` (possibly due to `FormGroup`?).
  //
  // ATOW `CheckboxControl` itself is not used on its own, though if we ever
  // need to, we'll need to sort out what's up.
  checkIcon: {
    position: 'absolute',
    fontSize: '1.25rem',
    left: '15px',
    display: 'none',
  },
}));

export type CheckboxControlProps = React.ComponentProps<typeof Checkbox> & {
  label: string;
  defaultOption: boolean;
};

export const CheckboxControl: React.VFC<CheckboxControlProps> = ({
  label,
  defaultOption,
  ...rest
}) => {
  const styles = useStyles();

  return (
    <FormControlLabel
      className={styles.checkboxLabel}
      control={
        <>
          <Checkbox {...rest} defaultChecked={defaultOption} />
          {/* This additional icon is leveraged to show the check when `:active` */}
          <CheckIcon className={styles.checkIcon} />
        </>
      }
      label={label}
    />
  );
};
