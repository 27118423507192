import { SvgIcon } from '@material-ui/core';
import React from 'react';

import type { SvgIconProps } from '@material-ui/core/SvgIcon';

export const Success: React.VFC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7071 4.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L1.29289 8.70711C0.902369 8.31658 0.902369 7.68342 1.29289 7.29289C1.68342 6.90237 2.31658 6.90237 2.70711 7.29289L6 10.5858L13.2929 3.29289C14.2048 2.38094 15.6191 3.79516 14.7071 4.70711Z"
      fill="#29C278"
    />
  </SvgIcon>
);
