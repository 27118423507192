import { CircularProgress as MuiCircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  circle: {
    strokeLinecap: 'round',
  },
  colorPrimary: {
    color: theme.palette.common.gray300,
  },
  indeterminate: {
    color: theme.palette.common.blue400,
    animationDuration: '650ms',
    position: 'absolute',
    left: 0,
  },
}));

type MuiProps = React.ComponentProps<typeof MuiCircularProgress>;

export type CircularProgressProps = Omit<MuiProps, 'color'>;

export const CircularProgress: React.VFC<CircularProgressProps> = ({
  classes,
  ...rest
}) => {
  const overrideClasses = useStyles();
  return (
    <MuiCircularProgress
      {...rest}
      classes={{ ...(classes ?? null), ...overrideClasses }}
    />
  );
};
