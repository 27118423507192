import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    formGroupRoot: {
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(-1),
      marginBottom: '1.75rem',
    },
    withError: {
      borderRadius: 5,
      boxShadow: `0 0 1px 1px ${theme.palette.common.red400}`,
      marginBottom: 0,
    },
  }),
);
