import { InputLabel } from '@material-ui/core';
import cx from 'classnames';
import React, { useMemo } from 'react';

import { QuestionLayout } from '../../components/QuestionLayout/QuestionLayout';
import { Typography } from '../../components/Typography';
import { useStyles } from './styles';

export interface QuestionProps {
  hint?: React.ReactNode;
  question: string;
  id: string;
  required?: boolean;
  dense?: boolean;
  isReachApplicationFormEnabled?: boolean;
}

export const Question: React.FC<QuestionProps> = ({
  id,
  hint,
  question,
  children,
  required,
  dense,
  isReachApplicationFormEnabled,
}) => {
  const styles = useStyles();

  const questionSize = useMemo(() => {
    if (dense) {
      if (isReachApplicationFormEnabled) return 'headingXS';
      return 'headingSM';
    }
    return 'headingMD';
  }, [dense, isReachApplicationFormEnabled]);

  return (
    <QuestionLayout
      input={children}
      question={
        <InputLabel htmlFor={id}>
          <Typography
            variant={questionSize}
            className={cx(styles.questionText, {
              [styles.required]: required,
            })}
          >
            {question}
          </Typography>
        </InputLabel>
      }
      hint={hint}
      dense={dense}
    />
  );
};
