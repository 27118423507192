import { TextField as MuiTextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { useFormHelperTextStyles } from '../FormHelperText';
import { Typography } from '../Typography';
import { withMuiError } from '../WithMuiError';

const useInputStyles = makeStyles(theme => ({
  root: {
    border: '1px',
    borderRadius: '5px',
    // Add margin to OutlinedInput to avoid layout shift when hiding/showing helper text
    // equal to height of text + 8px top margin
    marginBottom: '1.75rem',
    '&.Mui-error': { margin: 0 },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.gray300,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.gray800,
    },
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.red400,
    },
  },
  multiline: {
    padding: 0,
  },
  adornedEnd: {
    paddingRight: theme.spacing(2),
  },
  input: {
    padding: theme.spacing(1.5, 2),
    minHeight: theme.spacing(3),
  },
}));

type MuiProps = React.ComponentProps<typeof MuiTextField>;

// Ruby uses \A and \z as start and end anchors. JavaScript uses ^ and $.
const translateAnchors = (re?: string): string | undefined =>
  re?.replace('\\A', '^')?.replace('\\z', '$');

type TextFieldProps = Omit<MuiProps, 'variant'> & {
  id: string;
  pattern?: string;
};

const TextField: React.VFC<TextFieldProps> = ({
  error,
  FormHelperTextProps,
  helperText,
  InputProps,
  inputProps,
  pattern,
  ...rest
}) => {
  const inputClasses = useInputStyles();
  const formHelperTextClasses = useFormHelperTextStyles();
  const mergedInputProps = {
    ...inputProps,
    pattern: translateAnchors(pattern),
  };
  return (
    <MuiTextField
      {...rest}
      variant="outlined"
      fullWidth
      error={error}
      helperText={error && helperText}
      InputProps={{
        ...InputProps,
        classes: { ...(InputProps ?? {}).classes, ...inputClasses },
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props -- Not really duplicate, lower and uppercase are distinct props
      inputProps={mergedInputProps}
      FormHelperTextProps={{
        ...FormHelperTextProps,
        classes: {
          ...(FormHelperTextProps ?? {}).classes,
          ...formHelperTextClasses,
        },
        // @ts-expect-error `FormHelperTextProps` does have a `component` (via `OverrideProps`, but the MUI v4 props don't make it visible)
        component: Typography,
      }}
    />
  );
};

// withMuiError adds props so wrap it outside the definition to make those available to consumers
const ValidatedTextField = withMuiError(TextField);
ValidatedTextField.displayName = 'TextField';

export { TextField as BaseTextField, ValidatedTextField as TextField };

type ValidatedTextFieldProps = React.ComponentProps<typeof ValidatedTextField>;
export type { ValidatedTextFieldProps as TextFieldProps };
