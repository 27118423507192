import React from 'react';

import { RichTextWithEmbeds } from 'components/RichText';
import { Typography } from 'components/Typography';

import { hasNonWhitespaceContent } from './hasContent';

export interface TextBodyProps {
  customContent?: string;
  fallback: React.ReactNode;
  hideFallback?: boolean;
  className?: string;
}
export const TextBody: React.VFC<TextBodyProps> = ({
  customContent,
  fallback,
  hideFallback,
  className,
}) => {
  const showCustom = customContent && hasNonWhitespaceContent(customContent);
  const showFallback = !showCustom && !hideFallback;
  return (
    <div>
      {showCustom && (
        <Typography component="div">
          <RichTextWithEmbeds text={customContent} className={className} />
        </Typography>
      )}
      {showFallback && fallback}
    </div>
  );
};
