import { Box, InputAdornment } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import React from 'react';
import { useIntl } from 'react-intl';

import { CircularProgress } from '../CircularProgress';

export type DisplayValidIconProps = {
  validationStart: boolean;
  validationEnd: boolean;
  id?: string;
};

export function DisplayValidIcon({
  validationStart,
  validationEnd,
  id,
}: DisplayValidIconProps) {
  if (validationEnd) return <CheckmarkIcon id={id} />; // eslint-disable-line @typescript-eslint/no-use-before-define

  if (validationStart) return <LoadingSpinner id={id} />; // eslint-disable-line @typescript-eslint/no-use-before-define

  return null;
}

function LoadingSpinner({ id }: { id?: string }) {
  const intl = useIntl();

  return (
    <InputAdornment position="end" id={id} aria-live="polite">
      <Box position="relative" height={16} width={16}>
        <CircularProgress
          variant="determinate"
          size={16}
          thickness={4}
          value={100}
        />
        <CircularProgress
          role="progressbar"
          aria-label={intl.formatMessage({
            defaultMessage: 'Validation is in progress',
            description: 'Indicates validation is in progress',
          })}
          variant="indeterminate"
          disableShrink
          size={16}
          thickness={4}
        />
      </Box>
    </InputAdornment>
  );
}

function CheckmarkIcon({ id }: { id?: string }) {
  const intl = useIntl();

  return (
    <InputAdornment position="end" id={id}>
      <Check
        fontSize="inherit"
        role="status"
        aria-hidden={false}
        aria-label={intl.formatMessage({
          defaultMessage: 'Validation succeeded',
          description: 'Indicates validation is successful',
        })}
      />
    </InputAdornment>
  );
}
