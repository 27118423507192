import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  shortAnswer: {
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1.5, 2),
      height: theme.spacing(3),
    },
  },
}));
