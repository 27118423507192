import React, { useCallback, useReducer } from 'react';
import { FormattedMessage } from 'react-intl';

export type CustomValidityHandler = (valid: boolean) => void;

// This leverages the browser constraint API to set the error state on a MUI
// input component. The form can continue to see the input as uncontrolled
// but we don't lose the ability to use all the MUI provided styling this way,
// which requires setting props instead of css
export interface Error {
  error?: boolean;
  helperText?: React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | null | undefined;
  // provided to allow components with complex validity logic report their validation state explicitly
  // since not everything can be linked to a change event on an input directly. Removed from the public API
  // provided by the higher order component.
  onValidityChange?: CustomValidityHandler;
}

export interface ConditionalError {
  shouldValidate?: boolean;
  helperText?: React.ReactNode;
  patternMismatchText?: React.ReactNode;
  valueMissingText?: React.ReactNode;
}

type MessageKeys = Pick<
  ValidityState,
  'patternMismatch' | 'valueMissing' | 'customError'
>;

const mapErrorMessage = (
  validityState: ValidityState,
  messages: Record<keyof MessageKeys, React.ReactNode | undefined>,
  helperText?: React.ReactNode,
): React.ReactNode => {
  let message;
  if (validityState.patternMismatch) {
    message = messages.patternMismatch;
  }
  if (validityState.valueMissing) {
    message = messages.valueMissing;
  }
  if (validityState.customError) {
    message = messages.customError;
  }
  return message ?? helperText;
};

interface ErrorState {
  helperText?: React.ReactNode;
  isInvalid: boolean;
}

interface InvalidAction {
  type: 'invalid';
  message: React.ReactNode;
}

interface ValidAction {
  type: 'valid';
}

const valueMissingFallback = (
  <FormattedMessage
    defaultMessage="Please enter a value"
    description="Fallback error message stating a field is required"
  />
);
type Action = InvalidAction | ValidAction;

const reducer = (state: ErrorState, action: Action): ErrorState => {
  switch (action.type) {
    case 'valid':
      if (state.isInvalid) {
        return { isInvalid: false };
      }
      break;
    case 'invalid':
      if (!state.isInvalid || state.helperText !== action.message) {
        return { isInvalid: true, helperText: action.message };
      }
      break;
    default:
      break;
  }
  return state;
};

export const withMuiError =
  <P extends Error>(
    Component: React.ComponentType<P>,
    useManualValidation?: boolean,
  ): React.FC<Omit<P, 'onValidityChange'> & ConditionalError> =>
  ({
    shouldValidate,
    helperText,
    patternMismatchText,
    valueMissingText = valueMissingFallback,
    onChange,
    ...props
  }) => {
    const [invalid, dispatch] = useReducer(reducer, { isInvalid: false });
    // onInvalid handler called when the form reports validity (user clicking submit for example)
    const handleInvalid = useCallback(
      (e: React.InvalidEvent<HTMLInputElement>) => {
        if (e.target.validity.valid) {
          dispatch({ type: 'valid' });
          return;
        }
        const newErrorMessage = mapErrorMessage(
          e.target.validity,
          {
            patternMismatch: patternMismatchText,
            valueMissing: valueMissingText,
            customError: e.target.validationMessage,
          },
          helperText,
        );
        dispatch({ type: 'invalid', message: newErrorMessage });
      },
      [helperText, patternMismatchText, valueMissingText],
    );

    // if the user fixes the input, we should remove the error styling
    // this can be used on change and blur in case a complex input prevents change from bubbling
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(e);
        }
        if (e.target.validity.valid) {
          dispatch({ type: 'valid' });
        }
      },
      [onChange],
    );

    const handleValidityChange = useCallback((valid: boolean) => {
      if (valid) {
        dispatch({ type: 'valid' });
      } else {
        dispatch({ type: 'invalid', message: '' });
      }
    }, []);

    const componentProps = {
      error: shouldValidate && invalid.isInvalid,
      ...(props as P),
      onInvalid: handleInvalid,
      onChange: handleChange,
      onBlur: handleChange,
      helperText: invalid.helperText,
    };

    if (useManualValidation) {
      componentProps.onValidityChange = handleValidityChange;
    }
    return <Component {...componentProps} />;
  };
