import { Snackbar as MuiSnackbar, SnackbarContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { Content, Severity } from './Content/Content';

const useSnackbarContentOverrideStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray800,
    borderRadius: theme.misc.borderRadius,
    border: `1px solid ${theme.palette.common.gray300}`,
    boxShadow: '0px 8px 16px rgba(19, 30, 64, 0.12)',
  },
}));

type MuiProps = React.ComponentProps<typeof MuiSnackbar>;
export interface SnackbarProps extends MuiProps {
  severity: Severity;
  message: React.ReactNode;
}

export const Snackbar: React.VFC<SnackbarProps> = ({
  autoHideDuration = 6000,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  severity,
  message,
  ...muiSnackbarProps
}) => {
  const snackbarContentOverrideClasses = useSnackbarContentOverrideStyles();
  return (
    <MuiSnackbar
      {...muiSnackbarProps}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
    >
      <SnackbarContent
        classes={snackbarContentOverrideClasses}
        message={<Content severity={severity} message={message} />}
      />
    </MuiSnackbar>
  );
};
